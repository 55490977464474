import { RequestErrorTypes } from '@epitech/ops-panoramix-types';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';

import { environment } from '@/config/environment';

import { AuthState, logoutUser, tokenReceived } from './authentication.slice';

const mutex = new Mutex();

const tokenQuery = fetchBaseQuery({
  baseUrl: environment.services.USERS_URI,
  credentials: 'include',
});

function isValidTokenResponse(data: unknown): data is { jwt: string } {
  return !!data && typeof data === 'object' && 'jwt' in data;
}

function isJwtExpired(error: FetchBaseQueryError) {
  return (
    error &&
    error.status === 401 &&
    !!error.data &&
    typeof error.data === 'object' &&
    'name' in error.data &&
    error.data?.name === RequestErrorTypes.TokenExpired
  );
}

export function customFetchQuery(
  baseUrl: string,
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> {
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as { auth: AuthState }).auth.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });

  return async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && isJwtExpired(result.error)) {
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        // lock the mutex
        const release = await mutex.acquire();
        try {
          const refreshResult = await tokenQuery('/users/token', api, extraOptions);

          if (refreshResult.data && isValidTokenResponse(refreshResult.data)) {
            api.dispatch(tokenReceived(refreshResult.data));
            result = await baseQuery(args, api, extraOptions);
          } else {
            api.dispatch(logoutUser());
          }
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
    return result;
  };
}
