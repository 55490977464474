import { useColorMode } from '@chakra-ui/react';
import { EventRegisteredSample } from '@epitech/ops-panoramix-events-types';
import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { filterEvents, ICalendarFilters } from '@/components/calendar/filters/utils';
import {
  getAllDaySetter,
  getModuleColorMapper,
  sanitizeRegisteredSamplePayload,
} from '@/lib/helpers/events.helpers';
import { useSelector } from '@/lib/hooks/useSelector';
import type { SanitizedEvent } from '@/lib/types/events';
import { useGetEventsWithRegisteredStatusQuery } from '@/store/services/events';
export interface IEventsFetcherProps {
  filters: ICalendarFilters;
  start: string;
  end: string;
}

export type EventsFetcherResult = {
  events: SanitizedEvent<EventRegisteredSample>[];
  isLoading: boolean;
};

export function useEventsFetcher(
  { filters, start, end }: IEventsFetcherProps,
  skip = false,
): EventsFetcherResult {
  const { colorMode } = useColorMode();
  const {
    data: rawEvents = [],
    isLoading,
    isFetching,
  } = useGetEventsWithRegisteredStatusQuery(
    { start, end },
    { refetchOnMountOrArgChange: true, skip },
  );
  const moduleColors = useSelector(state => state.settings.moduleColors, shallowEqual);

  const getModuleColor = useMemo(
    () => getModuleColorMapper(moduleColors, colorMode),
    [moduleColors, colorMode],
  );

  const setAllDay = useMemo(() => getAllDaySetter<EventRegisteredSample>(start, end), [start, end]);

  const events = useMemo(() => {
    const sanitizedEvents = rawEvents.map(sanitizeRegisteredSamplePayload);
    return filterEvents(sanitizedEvents, filters).map(event => ({
      ...setAllDay(event),
      ...getModuleColor(event),
    }));
  }, [rawEvents, filters, setAllDay, getModuleColor]);

  return {
    events,
    isLoading: isLoading || isFetching,
  };
}
