import { type StackProps, Text, VStack } from '@chakra-ui/react';
import React, { type PropsWithChildren } from 'react';

interface LabelledContainerProps extends StackProps {
  label: string;
}
function LabelledContainer({
  label,
  children,
  ...stackProps
}: PropsWithChildren<LabelledContainerProps>) {
  return (
    <VStack gap={2} alignItems="stretch" {...stackProps}>
      <Text textStyle="muted-header" fontSize="xs" color="secondFontColor">
        {label}
      </Text>
      <Text fontFamily="mono" layerStyle="container" fontSize="sm" p={4}>
        {children}
      </Text>
    </VStack>
  );
}

export default React.memo(LabelledContainer);
