import { ComponentStyleConfig, cssVar } from '@chakra-ui/react';

const $arrowBg = cssVar('popper-arrow-bg');

export const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    bg: 'primaryBgColor',
    border: '1px solid',
    borderColor: 'primaryColor',
    color: 'primaryFontColor',
    [$arrowBg.variable]: 'secondBgColor',
    borderRadius: 'md',
    py: 2,
    px: 4,
    boxShadow: 'lg',
  },
};
