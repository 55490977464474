const addDays = (date: Date, days: number) => new Date(date.getTime() + days * 24 * 60 * 60 * 1000);

const easter = (year: number) => {
  const a = year % 19;
  const century = Math.floor(year / 100);
  const yearsAfterCentury = year % 100;
  const d =
    (19 * a +
      century -
      Math.floor(century / 4) -
      Math.floor((Math.floor(century - (century + 8) / 25) + 1) / 3) +
      15) %
    30;
  const e =
    (32 + 2 * (century % 4) + 2 * Math.floor(yearsAfterCentury / 4) - d - (yearsAfterCentury % 4)) %
    7;
  const f = d + e - 7 * Math.floor((a + 11 * d + 22 * e) / 451) + 114;
  const month = Math.floor(f / 31);
  const day = (f % 31) + 1;

  return new Date(year, month - 1, day);
};

export const getPublicHolidayForYear = (year: number, alsace = false) => {
  const days = [
    new Date(year, 10, 11).getTime(),
    addDays(easter(year), 39).getTime(),
    new Date(year, 7, 15).getTime(),
    new Date(year, 6, 14).getTime(),
    new Date(year, 4, 1).getTime(),
    new Date(year, 0, 1).getTime(),
    addDays(easter(year), 50).getTime(),
    addDays(easter(year), 1).getTime(),
    new Date(year, 11, 25).getTime(),
    new Date(year, 10, 1).getTime(),
    new Date(year, 4, 8).getTime(),
  ];

  if (alsace) {
    days.push(new Date(year, 11, 26).getTime());
    days.push(addDays(easter(year), -2).getTime());
  }

  return days;
};
