import { useAbility } from '@casl/react';
import { Flex, Spinner } from '@chakra-ui/react';
import { DateSelectArg, DatesSetArg, DayCellContentArg, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import integrationPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timeGridPlugin from '@fullcalendar/timegrid';
import isSameDay from 'date-fns/isSameDay';
import React, { useCallback, useRef, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { CalendarFilters, CalendarHeader, ICalendarFiltersProps } from '@/components/calendar';
import { renderCalendarEventItem } from '@/components/calendar/EventItem';
import { useCalendarFilters } from '@/components/calendar/filters/useFilters';
import { environment } from '@/config/environment';
import { AbilityContext } from '@/config/providers/ability.context';
import { getPublicHolidayForYear } from '@/lib/helpers/publicHolidays.helper';
import useProfilePublicHoliday from '@/lib/hooks/useProfilePublicHoliday';
import { ICalendarView } from '@/lib/types/calendar';
import { updatePublicHoliday } from '@/store/services/settings.slice';

import { useEventsFetcher } from './useEventsFetcher';
import { useEventTimelineFetcher } from './useEventTimelineFetcher';
import { DEFAULT_CALENDAR_PARAMS, DEFAULT_ROOMS_PARAMS } from './utils';

export interface CalendarView {
  isTimeline: boolean;
}

export function Calendar({ isTimeline }: CalendarView) {
  const { t } = useTranslation('pages/Calendar');
  const location = useLocation();
  const dispatch = useDispatch();
  const [isPending, startTransition] = useTransition();
  const [query, setQuery] = useSearchParams(
    isTimeline ? DEFAULT_ROOMS_PARAMS : DEFAULT_CALENDAR_PARAMS,
  );
  const navigate = useNavigate();
  const calendarRef: React.LegacyRef<FullCalendar> = useRef(null);
  const ability = useAbility(AbilityContext);
  const queryView = query.get('view') as ICalendarView;
  const queryStart = query.get('start') as string;
  const queryEnd = query.get('end') as string;

  const { filters, upsertFilters, resetFilterEntry } = useCalendarFilters();
  const { events, isLoading } = useEventsFetcher(
    { filters, start: queryStart, end: queryEnd },
    isTimeline,
  );
  const [showWorkingHours, setShowWorkingHours] = React.useState(true);
  const {
    rooms,
    events: resourceEvents,
    loading: isTimelineLoading,
  } = useEventTimelineFetcher(
    {
      filters,
      start: queryStart,
      end: queryEnd,
    },
    !isTimeline,
  );

  const onCalendarViewChange = (view: string) => {
    setQuery(prev => ({
      ...Object.fromEntries(prev),
      view: view,
    }));
  };

  const onDatesChange = (dates: DatesSetArg) => {
    const { start, end, view } = dates;
    const newStartStr = start.toISOString();
    const newEndStr = end.toISOString();

    if (!isSameDay(new Date(queryStart), start) || !isSameDay(new Date(queryEnd), end)) {
      startTransition(() => {
        setQuery(prev => ({
          ...Object.fromEntries(prev),
          view: view.type,
          start: newStartStr,
          end: newEndStr,
        }));
      });
    }
  };

  const onEventClick = (arg: EventClickArg) => {
    const { _id } = arg.event.extendedProps;
    if (isTimeline) return;
    navigate({
      pathname: `/calendar/events/${_id}/details`,
      search: location.search,
    });
  };

  const onEventAddDrag = (args: DateSelectArg) => {
    const { start, end } = args;
    if (isTimeline) return;

    navigate(
      {
        pathname: `/calendar/events/create`,
        search: location.search,
      },
      {
        state: {
          start,
          end,
        },
      },
    );
  };

  const profilePublicHoliday = useProfilePublicHoliday();

  const publicHolidayRender = useCallback(
    async (info: DayCellContentArg) => {
      const { date, el } = info;
      const year = date.getFullYear();

      if (!profilePublicHoliday || !profilePublicHoliday[year]) {
        // await mandatory, doesn't work without. force redux to add data before continuing
        await dispatch(updatePublicHoliday({ [year]: getPublicHolidayForYear(year) }));
      }

      const dayOff = profilePublicHoliday[year].includes(date.getTime());

      if (dayOff) {
        el.classList.add('publicHoliday');
      }
    },
    [dispatch, profilePublicHoliday],
  );

  const hasAllDayEvents = events.some(
    (event): boolean => typeof event.allDay !== 'undefined' && event.allDay !== false,
  );

  const goToDate: ICalendarFiltersProps['goToDate'] = useCallback(
    date => {
      if (calendarRef.current) {
        calendarRef.current.getApi().gotoDate(date);
      }
    },
    [calendarRef],
  );

  const onWorkingHoursChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setShowWorkingHours(e.target.checked);
  }, []);

  return (
    <Flex minW="300px" direction="column" w="full" h="full">
      {calendarRef.current && (
        <>
          <CalendarFilters
            goToDate={goToDate}
            filters={filters}
            upsertFilters={upsertFilters}
            resetFilterEntry={resetFilterEntry}
            excludeFilters={isTimeline ? ['search', 'curriculum', 'visible'] : []}
          />
          <CalendarHeader
            goToDate={goToDate}
            isTimeline={isTimeline}
            calendarRef={calendarRef}
            onWorkingHoursChange={onWorkingHoursChange}
            locale={t('language')}
            onCalendarViewChange={onCalendarViewChange}
          />
        </>
      )}
      <FullCalendar
        scrollTime="09:00:00"
        allDaySlot={hasAllDayEvents}
        ref={calendarRef}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          integrationPlugin,
          listPlugin,
          resourceTimelinePlugin,
        ]}
        resourceOrder={'city, title'}
        resourceAreaHeaderContent={t('rooms')}
        resourcesInitiallyExpanded={true}
        resourceAreaWidth={'15%'}
        initialView={queryView}
        initialDate={queryStart}
        firstDay={1}
        locale={t('language')}
        allDayText=""
        eventResourceEditable={false}
        resourceGroupField="city"
        resources={isTimeline ? rooms : []}
        events={isTimeline ? resourceEvents : events}
        height="100%"
        datesSet={onDatesChange}
        expandRows={true}
        fixedWeekCount={false}
        headerToolbar={false}
        eventClick={onEventClick}
        selectable={isTimeline ? false : ability.can('create', 'event')}
        select={onEventAddDrag}
        selectMirror={true}
        eventContent={renderCalendarEventItem}
        noEventsContent={t('no_event')}
        slotMinWidth={isTimeline && showWorkingHours ? 30 : 100}
        slotMinTime={isTimeline && showWorkingHours ? '08:00:00' : '00:00:00'}
        slotMaxTime={isTimeline && showWorkingHours ? '19:00:00' : '24:00:00'}
        schedulerLicenseKey={environment.FULLCALENDAR_LICENSE}
        dayCellDidMount={publicHolidayRender}
        weekNumbers={true}
        weekText={t('weekNumbers')}
      />
      <Spinner
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        size="lg"
        display={isLoading || isPending || isTimelineLoading ? 'inline-block' : 'none'}
      />
    </Flex>
  );
}
