import React from 'react';
import { Outlet } from 'react-router-dom';

import { Calendar } from './Calendar';

export function CalendarPage() {
  return (
    <>
      <Calendar isTimeline={false} />
      <Outlet />
    </>
  );
}
