import { extendTheme, theme as baseTheme, ThemeOverride } from '@chakra-ui/react';

import { breakpointsStyles } from './breakpointsStyles';
import {
  Accordion,
  Button,
  Container,
  Divider,
  Input,
  Link,
  List,
  Menu,
  Modal,
  NumberInput,
  Popover,
  Stat,
  Table,
  Tabs,
  Tag,
  Text,
  Textarea,
  Tooltip,
} from './components';
import { layerStyles } from './layerStyles';
import { bgImageOverride, calendarStyle, datepickerStyle } from './overrides';
import { semanticTokens } from './semanticTokens';
import { textStyles } from './textStyles';
// https://chakra-ui.com/docs/theming/customize-theme

const _overrides: ThemeOverride = {
  semanticTokens,
  breakpoints: breakpointsStyles,
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  zIndices: {
    globalOverlay: 1900, // https://v2.chakra-ui.com/docs/styled-system/theme#z-index-values
  },
  fonts: {
    heading: 'Raleway',
    body: 'Open Sans',
  },
  colors: {
    brand: baseTheme.colors.blue,
  },
  textStyles,
  layerStyles,
  styles: {
    global: props => ({
      'html, body': {
        bg: 'mainBgColor',
        color: 'primaryFontColor',
        bgImage: bgImageOverride(props.colorMode),
      },
      body: {
        lineHeight: 1.5,
        WebkitFontSmoothing: 'antialiased',
      },
      /** Necessary for the Sentry dialog report to be above the modal z-index */
      '.sentry-error-embed-wrapper': {
        zIndex: '2000!important',
      },
      ...calendarStyle,
      ...datepickerStyle,
    }),
  },
  components: {
    Button,
    Container,
    Divider,
    Input,
    Link,
    List,
    Menu,
    Modal,
    NumberInput,
    Popover,
    Tag,
    Text,
    Textarea,
    Accordion,
    Table,
    Tooltip,
    Tabs,
    Stat,
  },
};

export const theme = extendTheme(_overrides);
