import { EventEntry } from '@epitech/ops-panoramix-events-types';
import type { EventRef } from '@epitech/ops-panoramix-types';

export const QUERY_KEYS = ['visible', 'modulesCode'] as const;
export const STATE_KEYS = ['search', 'curriculum', 'promotion', 'city'] as const;

export interface ICalendarQueryFilters extends Record<(typeof QUERY_KEYS)[number], unknown> {
  visible: boolean;
  modulesCode: string[];
}

export interface ICalendarStateFilters extends Record<(typeof STATE_KEYS)[number], unknown> {
  search: EventRef | null;
  curriculum: string | null;
  promotion: string | null;
  city: string | null;
}

export type ICalendarFilters = ICalendarQueryFilters & ICalendarStateFilters;

export const DEFAULT_QUERY_FILTERS: Partial<
  Record<keyof ICalendarQueryFilters, string | string[]>
> = {
  visible: 'true',
};

export const DEFAULT_STATE_FILTERS: ICalendarStateFilters = {
  search: null,
  curriculum: null,
  promotion: null,
  city: null,
};

export function getActiveFilters(filters: Partial<ICalendarStateFilters>): ICalendarFilters {
  return Object.keys(filters).reduce((acc, key) => {
    const value = filters[key as keyof ICalendarStateFilters];

    if (Array.isArray(value)) {
      if (value.length > 0) {
        return { ...acc, [key]: value };
      } else {
        return acc;
      }
    }
    if (value) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {}) as ICalendarFilters;
}

type FilterRawEventComparator = (event: Partial<EventEntry>) => boolean;

function getFiltersComparators(filters: Partial<ICalendarFilters>): FilterRawEventComparator[] {
  const activeFilters = getActiveFilters(filters);
  const comparators: FilterRawEventComparator[] = [];

  Object.entries(activeFilters).forEach(([key, value]) => {
    switch (key) {
      case 'modulesCode': {
        const val = value as ICalendarFilters['modulesCode'];
        comparators.push(event => {
          return event.moduleRef?.code ? val.includes(event.moduleRef?.code) : false;
        });
        break;
      }
      case 'visible': {
        const val = value as ICalendarFilters['visible'];
        comparators.push(event => {
          return event.visible === val;
        });
        break;
      }
      case 'search': {
        const val = value as ICalendarFilters['search'];
        comparators.push(event => {
          return event._id === val?._id;
        });
        break;
      }
      case 'promotion':
      case 'city':
      case 'curriculum': {
        const val = value as ICalendarFilters['promotion' | 'city' | 'curriculum'];
        comparators.push(event => {
          if (!event.cohortGroups) return false;
          return event.cohortGroups?.some(cohort => {
            return cohort[key]?._id === val;
          });
        });
        break;
      }
    }
  });
  return comparators;
}

export const filterEvents = <T extends Partial<EventEntry>>(
  events: T[],
  filters: Partial<ICalendarFilters>,
): T[] => {
  const comparators = getFiltersComparators(filters);
  return events.filter(event => {
    return comparators.every(comparator => comparator(event));
  });
};
