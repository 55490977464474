import React from 'react';

import { useProfileSettingsToInitFront } from '@/lib/hooks/useProfileSettingsToInitFront';

function InitAuthWrapper({ children }: React.PropsWithChildren) {
  useProfileSettingsToInitFront();

  return <>{children}</>;
}

export default React.memo(InitAuthWrapper);
