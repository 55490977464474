import { SystemStyleObjectRecord } from '@chakra-ui/react';

export const textStyles: SystemStyleObjectRecord = {
  h1: {
    fontSize: ['48px', '72px'],
    fontWeight: 'bold',
    lineHeight: '110%',
    letterSpacing: '-2%',
  },
  h2: {
    fontSize: ['36px', '48px'],
    fontWeight: 'semibold',
    lineHeight: '110%',
    letterSpacing: '-1%',
  },
  'popover-header': {
    fontSize: '16px',
    fontWeight: '600',
    color: 'gray.600',
    lineHeight: '110%',
    letterSpacing: '-1%',
  },
  'muted-header': {
    fontSize: 'xs',
    letterSpacing: 1.6,
    textTransform: 'uppercase',
    fontWeight: 'semibold',
  },
  'muted-label': {
    letterSpacing: 1.3,
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    color: 'secondFontColor',
  },
};
