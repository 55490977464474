import { createStandaloneToast } from '@chakra-ui/react';
import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import { theme } from '@/config/theme';
import i18n from '@/lib/hooks/i18n';

import { sanitizeError } from './errorHandler.helpers';

const { toast } = createStandaloneToast({ theme });

const skipErrorEndpoints = [''];

// Error codes used for warning, we usually wants to display a ConfirmDialog instead of a toast
const skipStatusCode = [400];

export const rtkQueryErrorLogger: Middleware = (_api: MiddlewareAPI) => next => action => {
  if (
    isRejectedWithValue(action) &&
    !skipErrorEndpoints.includes(action.meta.arg.endpointName) &&
    !skipStatusCode.includes(action.payload?.status) &&
    !toast.isActive('rtk-query-error')
  ) {
    toast({
      title: i18n.t('error_form', { ns: 'global' }),
      description: sanitizeError(action.payload),
      status: 'error',
      position: 'top',
      id: 'rtk-query-error',
      duration: 5000,
    });
  }
  return next(action);
};
