import { Flex, Grid, Icon, Text } from '@chakra-ui/react';
import { formatDate } from '@fullcalendar/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';

interface EventItemProps {
  moduleName?: string;
  start: Date;
  end: Date;
  title: string;
  id: string;
}

function _EventItem({ start, end, title, id }: EventItemProps) {
  const { t } = useTranslation('components/events');

  return (
    <Grid
      whiteSpace={'nowrap'}
      gridTemplateColumns={'min-content min-content 1fr min-content'}
      _hover={{ bg: 'moduleViewItemBgColorHover' }}
      lineHeight={8}
      pl={4}
      pr={4}
    >
      <Text>
        {formatDate(start, {
          locale: t('language'),
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}
      </Text>
      <Text ml={5}>
        {formatDate(end, {
          locale: t('language'),
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}
      </Text>
      <Text ml={4}>{title}</Text>
      <Flex justifyContent={'flex-end'} width={4}>
        <Link to={`/calendar/events/${id}/details`} target="_blank" rel="noopener noreferrer">
          <Icon as={FiExternalLink} />
        </Link>
      </Flex>
    </Grid>
  );
}

export const EventItem = React.memo(_EventItem);
