import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { FiChevronDown } from "react-icons/fi";

import { getDateFnsLanguage } from "@/config/localization";

interface ButtonDatePickerProps {
  title: string;
  onChange: (e: Date) => void;
  buttonTitle?: string;
}

function _ButtonDatePicker({title, onChange, buttonTitle}: ButtonDatePickerProps) {
  const initLanguage = useCallback((languageStr: string) => {
    const language = getDateFnsLanguage(languageStr);
    registerLocale(languageStr, language);
  }, []);

  const closePicker = () => {
    setIsOpen(false)
  }

  const togglePicker = () => {
    setIsOpen(!isOpen);
  }
  const [ isOpen, setIsOpen ] = useState(false);
  const { t: date_t } = useTranslation('components/shared');
  initLanguage(date_t('localeDateFns'));

  return (
    <ReactDatePicker
      portalId='root-portal'
      dateFormat={date_t('date')}
      locale={date_t('localeDateFns')}
      popperPlacement='bottom'
      selected={new Date()}
      shouldCloseOnSelect={true}
      title={title}
      onChange={onChange}
      open={isOpen}
      onCalendarClose={closePicker}
      customInput={
        <Flex height="48px" minW="230px" justifyContent={'center'} alignItems={'center'}>
          <Button
            onClick={togglePicker}
            variant="white"
            size={"sm"}
          >
            <Text
              fontSize={20}
              fontWeight="bold"
              lineHeight="48px"
              textAlign="center"
              verticalAlign="middle"
            >
              {buttonTitle}
            </Text>
            <Icon marginLeft={'2'} as={FiChevronDown} />
          </Button>
        </Flex>
      }
    />
  );
}

export const ButtonDatePicker = React.memo(_ButtonDatePicker);
 