import { statAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/system';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const normal = definePartsStyle({
  number: {
    opacity: 0.8,
    fontWeight: 'normal',
  },
});
export const Stat = defineMultiStyleConfig({
  variants: {
    normal,
  },
});
