import { GroupBase } from 'chakra-react-select';
import { isBoolean } from 'lodash';
import React, { useCallback, useState } from 'react';

import { withMemo } from '../shared/helpers';
import { AsyncSelectBase } from './Async.base';
import { AsyncSelectFormWrapper } from './Async.form';
import { IAsyncSelectProps } from './Async.types';

/** A simple "Redirect" component necessary as we cannot use conditional hooks. (https://fr.reactjs.org/docs/hooks-rules.html)
 *  If we detect a property `control` then we redirect to the {@link AsyncSelectFormWrapper} that binds automatically with `react-hook-form` from the `control` property.
 */
export function AsyncSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  hasFormContext = false,
  loadOptions,
  ...otherProps
}: IAsyncSelectProps<Option, IsMulti, Group>) {
  const [initOptions, setInitOptions] = useState<{
    defaultOptions: Option[];
    isLoading?: boolean;
  }>({
    defaultOptions: [],
    isLoading: false,
  });

  /**   By default `defaultOptions={true}` will fetch `loadOptions('')` on component mount
        which can make unnecessary network call if the Select is not interacted with, this delay
        the network call to onFocus event.
  */
  const onFocus = useCallback(() => {
    if (!initOptions.defaultOptions.length) {
      setInitOptions(currentOptions => ({ ...currentOptions, isLoading: true }));

      loadOptions('').then(defaultOptions => {
        setInitOptions(() => ({ defaultOptions, isLoading: false }));
      });
    }
  }, [loadOptions, initOptions.defaultOptions.length]);

  return hasFormContext ? (
    <AsyncSelectFormWrapper
      {...otherProps}
      onFocus={onFocus}
      defaultOptions={
        isBoolean(otherProps.defaultOptions)
          ? initOptions.defaultOptions || otherProps.defaultOptions
          : otherProps.defaultOptions
      }
      loadOptions={loadOptions}
      isLoading={initOptions.isLoading || otherProps.isLoading}
    />
  ) : (
    <AsyncSelectBase
      {...otherProps}
      onFocus={onFocus}
      defaultOptions={initOptions.defaultOptions}
      loadOptions={loadOptions}
      isLoading={initOptions.isLoading || otherProps.isLoading}
    />
  );
}

export const AsyncSelectMemo = withMemo(AsyncSelect);
