import { HStack, Tag, Text } from '@chakra-ui/react';
import { chakraComponents, GroupBase, SelectComponentsConfig } from 'chakra-react-select';
import he from 'he';
import React from 'react';

import type { IModuleOption } from './helpers';

export const ModuleValue: SelectComponentsConfig<
  IModuleOption,
  false,
  GroupBase<IModuleOption>
>['SingleValue'] = React.memo(({ children, ...props }) => (
  <chakraComponents.SingleValue {...props}>
    <HStack spacing={2} alignItems="center">
      <Text flex="1">{children}</Text>
      <Tag ml="auto" mr={3} variant="moduleCode" width="fit-content">
        {he.decode(props.data.value.code)}
      </Tag>
    </HStack>
  </chakraComponents.SingleValue>
));

export const ModuleMultiValue: SelectComponentsConfig<
  IModuleOption,
  true,
  GroupBase<IModuleOption>
>['MultiValue'] = React.memo(({ children, ...props }) => (
  <chakraComponents.MultiValue {...props}>{props.data.value.code}</chakraComponents.MultiValue>
));
