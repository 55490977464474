import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { EventRegisteredSample } from '@epitech/ops-panoramix-events-types';
import { RoomRef } from '@epitech/ops-panoramix-types';
import { EventContentArg } from '@fullcalendar/core/index.js';
import React from 'react';
import { FiUser, FiUsers } from 'react-icons/fi';

import { getEventPrefix } from '@/lib/helpers/events.helpers';

const renderRegisteredIcon = (registrationType: EventRegisteredSample['registrationType']) =>
  registrationType === 'individual' ? FiUser : FiUsers;

const renderGridEventItem = (calendarEvent: EventContentArg) => {
  const event = calendarEvent.event._def.extendedProps as EventRegisteredSample;
  const prefix = getEventPrefix(event.moduleRef);

  return (
    <>
      {calendarEvent.view.type === 'dayGridMonth' && (
        <Box
          border="4px solid"
          borderColor={calendarEvent.backgroundColor}
          borderRadius="4px"
          boxSizing="content-box"
          h={0}
          m="7px 4px auto 4px"
          verticalAlign="top"
          w="0px"
        />
      )}
      <VStack alignItems="start" spacing={1} p={1} role="group" whiteSpace="pre-wrap" h="full" >
        <HStack alignItems="baseline" w="full" gap={2} lineHeight={1} flexWrap="wrap">
          {event.isRegistered && (
            <Icon
              as={renderRegisteredIcon(event.registrationType)}
              width={3}
              height={3}
              alignSelf="flex-end"
            />
          )}
          
        </HStack>
        <HStack alignItems="baseline" flexWrap="wrap" fontSize="xs" spacing={0} w="full">
          {prefix && (
            <Text fontFamily="mono" fontWeight="bold">
              {prefix}
              &nbsp;
            </Text>
          )}
          <Text textTransform="capitalize" overflow={"hidden"}>{calendarEvent.event.title}</Text>
        </HStack>
          <Text fontFamily="mono" fontSize="x-small" overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>
          {
            event?.roomsRef?.map((el: RoomRef, index: number) => el.name + `${index === event.roomsRef.length - 1 ? "" : " / "}`)
          }
          </Text>
      </VStack>
    </>
  );
};

const renderListEventItem = (calendarEvent: EventContentArg) => {
  const event = calendarEvent.event._def.extendedProps as EventRegisteredSample;
  const prefix = getEventPrefix(event.moduleRef);

  return (
    <HStack flexWrap="wrap" spacing={0} fontSize="xs" margin="auto" w="full">
      {prefix && (
        <Text fontFamily="mono" fontWeight="bold">
          {prefix}
          &nbsp;
        </Text>
      )}
      <Text textTransform="capitalize">{calendarEvent.event.title}</Text>
      {event.isRegistered && (
        <Icon ml="auto" as={renderRegisteredIcon(event.registrationType)} width={4} height={4} />
      )}
      <Text maxWidth="20vw" whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} marginLeft="auto">{
        event.roomsRef.map((el: RoomRef, index: number) => el.name + `${index === event.roomsRef.length - 1 ? "" : " / "}`)}
      </Text>
    </HStack>
  );
};

/** Needs to be a function and not a component since it is rendered by fullCalendar */
export function renderCalendarEventItem(calendarEvent: EventContentArg) {
  if (!calendarEvent) return null;

  if (
    calendarEvent.view.type === 'dayGridMonth' ||
    calendarEvent.view.type === 'timeGridWeek' ||
    calendarEvent.view.type === 'timeGridDay'
  )
    return renderGridEventItem(calendarEvent);
  else return renderListEventItem(calendarEvent);
}
