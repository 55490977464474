import { GroupBase } from 'chakra-react-select';
import React from 'react';

import { IAsyncSelectProps } from '../Async/Async.types';
import { ISelectProps } from '../Select.types';
import { IRef } from '../types';

export const isRefValue = (value: unknown): value is IRef =>
  typeof value === 'object' &&
  value !== null &&
  '_id' in value &&
  typeof (value as IRef)._id !== 'string';

export function withMemo<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  WrappedComponent:
    | React.JSXElementConstructor<ISelectProps<Option, IsMulti, Group>>
    | React.JSXElementConstructor<IAsyncSelectProps<Option, IsMulti, Group>>,
) {
  return React.memo((props: React.ComponentProps<typeof WrappedComponent>) => (
    <WrappedComponent {...(props as IAsyncSelectProps<Option, IsMulti, Group>)} />
  ));
}
