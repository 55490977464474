import { numberInputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { Input } from './Input';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  ...Input.baseStyle,
  field: { ...Input.baseStyle?.field, fontFamily: 'mono' },
  stepperGroup: {
    color: 'selectIconColor',
    backgroundColor: 'selectIconBgColor',
    _disabled: {
      backgroundColor: 'disabledBgColor',
    },
  },
});

export const NumberInput = defineMultiStyleConfig({
  baseStyle,
  variants: Input.variants,
});
