import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import LabelledContainer from '@/components/ui-elements/LabelledContainer';
import { environment } from '@/config/environment';

interface DebugPayloadProps {
  error?: string;
  componentStack: string;
  eventId: string;
}

function DebugPayload({ componentStack, error, eventId }: DebugPayloadProps) {
  if (!environment.__DEV__) {
    return null;
  }

  return (
    <>
      <LabelledContainer label="event id" w="full">
        {eventId}
      </LabelledContainer>
      <LabelledContainer label="payload" w="full">
        {error?.toString()}
      </LabelledContainer>
      <Accordion allowToggle position="relative">
        <AccordionItem>
          <AccordionButton
            justifyContent="space-between"
            px={4}
            mx={-4}
            w="calc(100% + 2rem)"
            borderRadius="sm"
          >
            <Text textStyle="muted-header" fontSize="xs" color="secondFontColor">
              Component stack
            </Text>
            <AccordionIcon color="secondFontColor" />
          </AccordionButton>
          <AccordionPanel p={4} maxH={200} overflowY="auto" layerStyle="container" mt={2}>
            <Text fontSize="xs" fontFamily="mono">
              {componentStack}
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}

export default React.memo(DebugPayload);
