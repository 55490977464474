import { SystemStyleObjectRecord } from '@chakra-ui/react';

export const layerStyles: SystemStyleObjectRecord = {
  base: {
    bgColor: 'secondBgColor',
    color: 'primaryFontColor',
    boxShadow: 'lg',
    borderRadius: 'md',
  },
  container: {
    bgColor: 'containerBgColor',
    color: 'primaryFontColor',
    boxShadow: 'inner',
    borderRadius: 'md',
  },
};
