import { ModuleEntry } from '@epitech/ops-panoramix-modules-types';
import type { ActivityRef, ModuleRef } from '@epitech/ops-panoramix-types';
import type { OptionBase } from 'chakra-react-select';

export interface IActivityOption {
  label: string;
  value: ActivityRef;
}

export interface IModuleOption extends OptionBase {
  label: string;
  value: ModuleRef;
}

export const getModuleOption = (module: ModuleRef): IModuleOption => ({
  value: module,
  label: module.name,
});

export const getActivitiesOptions = (module: ModuleEntry) =>
  module.activitiesRef && module.activitiesRef.length > 0
    ? module.activitiesRef?.map(activity => ({
        value: activity,
        label: activity.name,
      }))
    : [];
