import { HStack, Text } from '@chakra-ui/react';
import { chakraComponents, GroupBase, SelectComponentsConfig } from 'chakra-react-select';
import React, { PropsWithChildren } from 'react';

import { IStringOption } from '../types';

type CountryOptionProps = {
  code: string;
};

const _CountryOption = React.memo(({ children, code }: PropsWithChildren<CountryOptionProps>) => {
  const flagEmoji = code
    .toUpperCase()
    .replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397));

  return (
    <HStack align="center" w="full" h="full" spacing={2}>
      <Text display="block" transform="auto" translateY={0.4}>
        {flagEmoji}
      </Text>
      <Text size="xs">{children}</Text>
    </HStack>
  );
});

export const CountryOption: SelectComponentsConfig<
  IStringOption,
  false,
  GroupBase<IStringOption>
>['Option'] = React.memo(({ children, ...props }) => {
  return (
    <chakraComponents.Option {...props}>
      <_CountryOption code={props.data.value}>{children}</_CountryOption>
    </chakraComponents.Option>
  );
});

export const CountryValue: SelectComponentsConfig<
  IStringOption,
  false,
  GroupBase<IStringOption>
>['SingleValue'] = React.memo(({ children, ...props }) => (
  <chakraComponents.SingleValue {...props}>
    <_CountryOption code={props.data.value}>{children}</_CountryOption>
  </chakraComponents.SingleValue>
));
