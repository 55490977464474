import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  Tag,
  Text,
} from '@chakra-ui/react';
import { CityEvents } from '@epitech/ops-panoramix-events-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CityItem } from './CityItem';
import { CityList } from './CityList';
import { EventItem } from './EventItem';

interface ActivityItemProps {
  title: string;
  events: CityEvents[];
}

function _ActivityItem({ title, events }: ActivityItemProps) {
  const { t } = useTranslation('components/events');
  const nbEvent = useMemo(() => {
    if (events.length <= 0) {
      return 0;
    }
    return events.map(e => e.events.length).reduce((acc, e) => acc + e);
  }, [events]);

  return (
    <AccordionItem pb="0">
      <AccordionButton as="div">
        <Grid width="100%" as="span" textAlign="left" gridTemplateColumns={'1fr 1fr 1fr'}>
          <Text
            overflowX={'hidden'}
            textOverflow={'ellipsis'}
            maxWidth={'25vw'}
            whiteSpace={'nowrap'}
          >
            {title ? title : t('events_without_activities')}
          </Text>
          <Flex justifyContent={'start'}>
            {events.slice(0, 3).map((event, index) => (
              <Tag key={event.city} variant="roundXs">
                {event.city}
              </Tag>
            ))}
            {events.length > 3 && <CityList cities={events.map(e => e.city)} />}
          </Flex>
          <Box>
            <Tag backgroundColor="greenLightDarkBgColor" variant="roundXs">
              {t('created', { count: nbEvent, nbEvent })}
            </Tag>
          </Box>
        </Grid>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pl={4} pr={4} pb={4}>
        {events.length > 1 ? (
          events.map((event) => {
            return (
              <CityItem
                key={event.city}
                title={event.city}
                events={event.events}
                oppened={events.length === 1}
              />
            );
          })
        ) : events.length > 0 ? (
          events[0].events.map((event) => {
            return (
              <EventItem
                key={event._id}
                moduleName={event.moduleRef?.name}
                start={event.start}
                end={event.end}
                title={event.title}
                id={event._id}
              />
            );
          })
        ) : (
          <Text color="primaryFontColor" m={4}>
            {t('no_event_for_activity')}
          </Text>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}

export const ActivityItem = React.memo(_ActivityItem);
