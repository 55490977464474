import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleTabPanel = {
  p: 0,
};

const baseStyleTabPanels = {
  mt: 4,
};

export const Tabs = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    tabpanel: baseStyleTabPanel,
    tabpanels: baseStyleTabPanels,
  }),
});
