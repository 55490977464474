import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { rtkQueryErrorLogger, sentryReduxEnhancer } from './middlewares';
import { persistedAuthReducer } from './services/authentication.slice';
import { eventsApi } from './services/events';
import { modulesApi } from './services/modules';
import { settingsSlice } from './services/settings.slice';
import { usersApi } from './services/users';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['settings'],
  blacklist: [modulesApi.reducerPath, usersApi.reducerPath, eventsApi.reducerPath, 'auth'],
};

const rootReducer = combineReducers({
  [modulesApi.reducerPath]: modulesApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  auth: persistedAuthReducer,
  settings: settingsSlice.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      modulesApi.middleware,
      usersApi.middleware,
      eventsApi.middleware,
      rtkQueryErrorLogger,
    ),
  enhancers: defaultEnhancers => {
    return defaultEnhancers.concat(sentryReduxEnhancer);
  },
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
