import { set } from 'lodash';
import { useCallback, useMemo, useState, useTransition } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DEFAULT_QUERY_FILTERS,
  DEFAULT_STATE_FILTERS,
  ICalendarFilters,
  ICalendarQueryFilters,
  ICalendarStateFilters,
  STATE_KEYS,
} from './utils';

/**
 * Some filters are managed through state such as the search and the cohorts since there is not way to uniquely identify them through the URL other than internal panoramix values.
 * Modules are managed through `query` since they are unique and can be identified through the URL using their `code`.
 */
export function useCalendarFilters() {
  const [, startTransition] = useTransition();
  const [stateFilters, setStateFilters] = useState<ICalendarStateFilters>(DEFAULT_STATE_FILTERS);
  const [queryFilters, setQueryFilters] = useSearchParams();
  const rawModulesCode = queryFilters.get('modulesCode');
  const modulesCode = useMemo(() => rawModulesCode?.split(',') || [], [rawModulesCode]);
  const visible = queryFilters.get('visible') === 'true';

  const upsertFilters = useCallback(
    (filters: Partial<ICalendarFilters>) => {
      const newQueryFilters: Partial<ICalendarQueryFilters> = {};
      const newStateFilters: Partial<ICalendarStateFilters> = {};

      Object.entries(filters).forEach(([key, value]) => {
        if (STATE_KEYS.includes(key as keyof ICalendarStateFilters)) {
          set(newStateFilters, key, value);
        } else {
          if (Array.isArray(value)) {
            if (value.length) {
              set(newQueryFilters, key, value.join(','));
            } else {
              set(newQueryFilters, key, undefined);
            }
          } else {
            set(newQueryFilters, key, value);
          }
        }
      });

      startTransition(() => {
        if (Object.keys(newQueryFilters).length) {
          setQueryFilters(prevQueryFilters => ({
            ...Object.fromEntries(prevQueryFilters),
            ...newQueryFilters,
            visible:
              newQueryFilters.visible?.toString() || prevQueryFilters.get('visible') || 'true',
          }));
        }
        if (Object.keys(newStateFilters).length) {
          setStateFilters((prevFilters: ICalendarStateFilters) => ({
            ...prevFilters,
            ...newStateFilters,
          }));
        }
      });
    },
    [setQueryFilters],
  );

  const resetFilterEntry = useCallback(
    (key: keyof ICalendarFilters) => {
      startTransition(() => {
        if (STATE_KEYS.includes(key as keyof ICalendarStateFilters)) {
          setStateFilters((prevFilters: ICalendarStateFilters) => ({
            ...prevFilters,
            [key]: DEFAULT_STATE_FILTERS[key as keyof ICalendarStateFilters],
          }));
        } else {
          setQueryFilters(prevQueryFilters => {
            const defaultVal = DEFAULT_QUERY_FILTERS[key as keyof ICalendarQueryFilters];
            if (defaultVal) {
              prevQueryFilters.set(key, defaultVal as string);
            } else {
              prevQueryFilters.delete(key);
            }
            prevQueryFilters.delete(key);
            return prevQueryFilters;
          });
        }
      });
    },
    [setQueryFilters],
  );

  const filters = useMemo(
    () => ({
      ...stateFilters,
      modulesCode,
      visible,
    }),
    [modulesCode, stateFilters, visible],
  );

  return {
    filters,
    upsertFilters,
    resetFilterEntry,
  };
}
