import { listAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  item: {
    py: 2,
    fontSize: 'sm',
  },
});

const variants = {
  bordered: definePartsStyle({
    item: {
      '&:not(:first-of-type)': {
        borderTop: '1px solid',
        borderColor: 'separatorSecondaryBorderColor',
      },
    },
  }),
};

export const List = defineMultiStyleConfig({
  baseStyle,
  variants,
});
