import { tableAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  thead: {
    tr: {
      borderBottom: '1px solid',
      borderColor: 'separatorSecondaryBorderColor',
      th: {
        border: 'none',
      },
    },
  },
  tr: {
    td: {
      borderColor: 'separatorSecondaryBorderColor',
    },
    '.breaks': {
      textAlign: 'center',
      background: 'secondBgColor',
    },
    _last: {
      td: {
        border: 'none',
      },
    },
  },
});

export const Table = defineMultiStyleConfig({ baseStyle });
