import { FormLabel, Icon, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiInfo } from 'react-icons/fi';

interface FilterLabelProps {
  label: string;
}

function _FilterLabel({ label }: FilterLabelProps) {
  const { t } = useTranslation('layouts/calendar/Filters');

  return (
    <FormLabel w="auto">
      {t(label)}
      <Tooltip label={t(`${label}Tooltip`)} placement="right">
        <span>
          <Icon as={FiInfo} ml={2} verticalAlign="middle" />
        </span>
      </Tooltip>
    </FormLabel>
  );
}

export const FilterLabel = React.memo(_FilterLabel);
