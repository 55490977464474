import { ComponentMultiStyleConfig, theme } from '@chakra-ui/react';

export const Tag: ComponentMultiStyleConfig = {
  parts: ['container'],
  baseStyle: {},
  variants: {
    moduleCode: props => ({
      container: {
        ...theme.components.Badge.variants?.subtle(props),
        whiteSpace: 'nowrap',
        fontFamily: 'mono',
        color: 'blueGreenFontColor',
        bgColor: 'greyLightDarkBgColor',
      },
    }),
    roundXs: props => ({
      container: {
        ...theme.components.Badge.variants?.subtle(props),
        borderRadius: 20,
        fontSize: 'xs',
        mr: 2,
        width: 'fit-content',
      },
    }),
  },
};
