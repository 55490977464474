import { Avatar, AvatarProps } from '@chakra-ui/react';
import React from 'react';

interface IUserAvatarProps extends AvatarProps {
  user: {
    firstName: string;
    lastName: string;
  };
}

export const UserAvatar: React.FC<IUserAvatarProps> = React.memo(
  ({ user: { firstName, lastName }, ...avatarProps }) => (
    <Avatar name={`${firstName} ${lastName}`} {...avatarProps} />
  ),
);
