import { AbilityBuilder, createMongoAbility, MongoAbility } from '@casl/ability';
import {
  EventServiceAbilities,
  EventServiceActions,
  EventServiceSubjects,
} from '@epitech/ops-panoramix-events-types';
import {
  ModuleServiceAbilities,
  ModuleServiceActions,
  ModuleServiceSubjects,
} from '@epitech/ops-panoramix-modules-types';
import {
  UserServiceAbilities,
  UserServiceActions,
  UserServiceSubjects,
} from '@epitech/ops-panoramix-users-types';
import { createContext } from 'react';

export type PanoramixAbility = MongoAbility<
  EventServiceAbilities | ModuleServiceAbilities | UserServiceAbilities
>;

export const ability = new AbilityBuilder<PanoramixAbility>(createMongoAbility).build();

export type PanoramixSubjects = EventServiceSubjects | ModuleServiceSubjects | UserServiceSubjects;

export type PanoramixActions = EventServiceActions | ModuleServiceActions | UserServiceActions;

export const AbilityContext = createContext<typeof ability>(ability);
