import { useCallback, useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useDebouncedCallback<T extends (...args: any[]) => ReturnType<T>>(
  callback: T,
  delay: number,
) {
  const timeoutRef = useRef<number | null>(null);

  // Create a debounced version of the callback
  const debouncedCallback = useCallback(
    (...args: Parameters<T>) => {
      // Clear the previous timeout
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      // Set a new timeout
      timeoutRef.current = window.setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );

  // Cleanup timeout when the component is unmounted or delay/callback changes
  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [delay, callback]);

  return debouncedCallback;
}

export default useDebouncedCallback;
