import { ComponentStyleConfig } from '@chakra-ui/react';

export const Link: ComponentStyleConfig = {
  baseStyle: {
    transform: 'all 0.2s ease-in',
    _hover: {
      textDecoration: 'underline',
    },
  },
  variants: {
    subtle: {
      color: 'teal.500',
      _hover: {
        color: 'gray.800',
      },
    },
  },
  sizes: {
    sm: {
      fontSize: '12px',
    },
    md: {
      fontSize: '16px',
    },
  },
};
