import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleItem = {
  fontWeight: 'medium',
  lineHeight: 'normal',
};

const baseStyleList = {
  rounded: 'md',
  textAlign: 'start',
  boxShadow: 'lg',
  py: 0,
};

const baseStyleDivider = {
  my: 0,
};

const baseStyle = definePartsStyle({
  item: baseStyleItem,
  list: baseStyleList,
  divider: baseStyleDivider,
});

export const Menu = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'md',
  },
});
