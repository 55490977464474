import 'reflect-metadata';
import './app/lib/hooks/i18n';
import './app/config/providers/sentry';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from '@/App';
import { msalConfig } from '@/config/authentication';
import ErrorBoundaryWrapper from '@/config/providers/ErrorBoundaryWrapper';
import ProvidersWrapper from '@/config/providers/Wrapper';
import { theme } from '@/config/theme';
import { persistor, store } from '@/store/store';

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ProvidersWrapper store={store} persistor={persistor} theme={theme}>
        <ErrorBoundaryWrapper>
          <App />
        </ErrorBoundaryWrapper>
      </ProvidersWrapper>
    </MsalProvider>
  </React.StrictMode>,
);
