export const calendarStyle = {
  '.fc-daygrid-event, .fc-timegrid-event': {
    cursor: 'pointer',
    border: '2px solid transparent',
    transition: 'transform 0.1s ease-out',
    borderRadius: '4px',
    _hover: {
      outline: 'none',
    },
  },
  '.fc': {
    backgroundColor: 'white',
  },
  '.fc .fc-timegrid .fc-daygrid-body': {
    zIndex: '1 !important',
  },
  '.fc .fc-timegrid-slots td': {
    height: '45px',
  },
  '.fc .fc-timegrid-slots td.fc-timegrid-slot-label': {
    fontFamily: 'mono',
  },
  '.fc thead': {
    fontWeight: 300,
    fontFamily: 'mono',
  },
  '.fc thead th': {
    fontWeight: 'normal',
  },
  '.fc .fc-scroller::-webkit-scrollbar': {
    width: '10px',
    border: 'none',
  },
  '.fc .fc-scroller::-webkit-scrollbar-thumb': {
    background: '#e0e0e0',
    border: 'none',
  },
  '.fc-timegrid-event-harness.fc-timegrid-event-harness-inset .fc-event-main': {
    overflow: 'hidden',
  },
  '.fc-timegrid-event-harness.fc-timegrid-event-harness-inset:hover': {
    zIndex: '1000 !important',
  },
  'td.fc-timegrid-divider': {
    bg: 'secondBgColor', // '#f1f1f1',
  },
  'div.fc.fc-media-screen': {
    bg: 'thirdBgColor',
    color: 'primaryFontColor',
    'table, tbody > tr > th, tbody > tr > td, thead > tr > th': {
      borderColor: 'primaryBorderColor',
    },
    'table, .fc-scrollgrid > thead > tr.fc-scrollgrid-section-header > th, .fc-scrollgrid > tbody > tr.fc-scrollgrid-section-body > td':
      {
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
      },

    //        'tr.fc-scrollgrid-section-header > td': {
    //          border: '1px solid #ddd',
    //        },
    'td.fc-day-today': {
      bg: 'selectedBgColor',
    },
    'div.fc-list': {
      borderColor: 'primaryBorderColor',
      'div.fc-list-empty': {
        bg: 'thirdBgColor !important',
      },
    },
    'table.fc-list-table tbody': {
      'tr.fc-event': {
        cursor: 'pointer',
      },
      'tr:hover td': {
        backgroundColor: 'containerBgColor !important',
      },
      'tr th div': {
        backgroundColor: 'primaryBgColor !important',
      },
      'td.fc-list-event-title': {
        verticalAlign: 'middle',
      },
    },
  },
  'td.fc-day-sat, td.fc-day-sun, table tbody tr td.publicHoliday': {
    backgroundColor: 'publicHolidyBgColor',
  },
  'div.fc-timegrid-col-events div a.course': {
    border: '0px solid #fff',
    padding: '2px',
    backgroundImage:
      'repeating-linear-gradient(135deg, transparent, transparent 25px, rgb(0,0,0,0.15) 25px, rgb(0,0,0,0.15) 50px)',
  },
  '.fc .fc-resource-timeline .fc-resource-group:not([rowspan])': {
    backgroundColor: 'primaryBgColor',
  },
  'tr th .fc-datagrid-cell-frame .fc-datagrid-cell-cushion': {
    padding: '8px !important',
  },
  'tr td.fc-datagrid-cell .fc-datagrid-cell-frame .fc-datagrid-cell-cushion': {
    padding: '3px !important',
  },
  '.fc-h-event': {
    border: '0 !important',
  },
};
