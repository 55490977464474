import { GroupBase } from 'chakra-react-select';
import React from 'react';

import { SelectBase } from './Select.base';
import { SelectFormWrapper } from './Select.form';
import { ISelectProps } from './Select.types';
import { withMemo } from './shared/helpers';
import { IOption } from './types';

/** A simple "Redirect" component necessary as we cannot use conditional hooks. (https://fr.reactjs.org/docs/hooks-rules.html)
 *  If we detect a property `control` then we redirect to the {@link SelectFormWrapper} that binds automatically with `react-hook-form` from the `control` property.
 */
export function Select<
  Option extends IOption = IOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ hasFormContext = false, ...otherProps }: ISelectProps<Option, IsMulti, Group>) {
  return hasFormContext ? <SelectFormWrapper {...otherProps} /> : <SelectBase {...otherProps} />;
}

export const SelectMemo = withMemo(Select);
