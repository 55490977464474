import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  field: {
    borderRadius: 'md',
    border: '1px solid',
    borderColor: 'gray.200',
    color: 'gray.800',
    _placeholder: {
      color: 'mainPlaceholderColor',
    },
    _invalid: {
      borderColor: 'red.500',
    },
    _disabled: { bgColor: 'gray.200', color: 'gray.500' },
    _focus: {
      borderColor: '#3184ce',
      boxShadow: '0 0 0 1px #3184ce',
    },
  },
});

const variants = {
  white: definePartsStyle({
    field: {
      bgColor: 'containerBgColor',
      color: 'primaryFontColor',
      _disabled: {
        bgColor: 'disabledBgColor',
        color: 'gray.500',
      },
    },
  }),
};

export const Input = defineMultiStyleConfig({
  baseStyle,
  variants,
});
