import { Box, Flex, Portal, Spinner } from '@chakra-ui/react';
import React from 'react';

export function FullScreenOverlay(): React.ReactElement {
  return (
    <Portal>
      <Box
        position="fixed"
        inset={0}
        width="100vw"
        height="100vh"
        backgroundColor="rgba(45, 55, 72, 0.8)"
        zIndex="globalOverlay"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
      >
        <Flex justifyContent="center" alignItems="center" height="100%">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Flex>
      </Box>
    </Portal>
  );
}
