import { Locale } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';

const Locales = { enGB, fr };

export type LocaleKeys = keyof typeof Locales;

const getDateFnsLocale = (locale: LocaleKeys): Locale => Locales[locale] ?? Locales.enGB;

export const getDateFnsLanguage = (languageStr: string) => {
  const language = getDateFnsLocale(languageStr as LocaleKeys & 'localeDateFns');
  return language;
};

export const localesList = ['GB', 'FR'];

export type LocaleList = (typeof localesList)[number];
