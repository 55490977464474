import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  content: { boxShadow: 'md', borderRadius: 'md', fontWeight: 'normal' },
  closeButton: { color: 'primaryFontColor' },
  body: { padding: 0 },
  popper: { zIndex: 'popover' },
});

const variants = {
  noBorder: definePartsStyle({
    header: {
      borderBottom: 0,
    },
  }),
};

export const Popover = defineMultiStyleConfig({ baseStyle, variants });
