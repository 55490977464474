import { subject } from '@casl/ability';
import { permittedFieldsOf } from '@casl/ability/extra';
import { ColorMode } from '@chakra-ui/react';
import {
  EVENT_STUDENT_PAYLOAD_FIELDS,
  type EventEntry,
  type EventRegisteredSample,
  type EventSample,
  type EventSlotEntry,
  type EventStudentPayload,
  getSlotInput,
  HistoryEntry,
  type IEventFormInput,
} from '@epitech/ops-panoramix-events-types';
import type { ProjectGroupEntry } from '@epitech/ops-panoramix-modules-types';
import type { EventRef, ModuleRef, Stored } from '@epitech/ops-panoramix-types';
import type { UserEntry } from '@epitech/ops-panoramix-users-types';
import { parseISO } from 'date-fns';

import { DEFAULT_SLOT_PARAMS } from '@/config/constants';
import { PanoramixAbility } from '@/config/providers';
import { ModuleColors } from '@/store/services/settings.slice';

import type {
  DateObject,
  RawDateObject,
  RawDateObjectWithSlots,
  RawRegisteredSample,
  SanitizedDates,
  SanitizedDatesWithSlots,
  WithAllDay,
} from '../types/events';

export function isUserOrGroupAlreadyRegisteredToEvent(
  toRegister: Stored<UserEntry> | Stored<ProjectGroupEntry>,
  event: EventEntry | EventStudentPayload,
) {
  const isGroupMode = event?.registrationType === 'group';
  const isSlotMode = event?.type === 'appointment';

  if (!isSlotMode) {
    return isGroupMode
      ? event.registeredGroups.some(group => group._id === toRegister._id)
      : event.registeredUsers.some(user => user._id === toRegister._id);
  }
  return isGroupMode
    ? event.slots.some(slot => slot.registeredGroup?._id === toRegister._id)
    : event.slots.some(slot => slot.registeredUser?._id === toRegister._id);
}

/**
 *   Will cast any object with a `start` and `end` field to a DateObject
 */
export const sanitizeDateObject = <K extends RawDateObject>(dateObject: K): SanitizedDates<K> => {
  const { start, end } = dateObject;
  return { ...dateObject, start: parseISO(start), end: parseISO(end) };
};

export const sanitizeRegisteredSamplePayload = (
  registeredSample: RawRegisteredSample,
): EventRegisteredSample => {
  /** If `isRegistered` contains `start` and `end` properties, then we set the `start` and `end` of the event
   *   to these values so the user has his registerered slot as display range
   */
  if (
    registeredSample.type === 'appointment' &&
    typeof registeredSample.isRegistered === 'object'
  ) {
    return {
      ...registeredSample,
      ...sanitizeDateObject(registeredSample.isRegistered),
      isRegistered: sanitizeDateObject(registeredSample.isRegistered),
    };
  }
  return { ...registeredSample, ...sanitizeDateObject(registeredSample) } as EventRegisteredSample;
};

/**
 * Will cast all `DateString` in an event object to `Date` objects and apply casl subject type
 */
export const sanitizeEventPayload = <K extends RawDateObjectWithSlots>(
  event?: K,
): SanitizedDatesWithSlots<K> | null => {
  if (!event) {
    return null;
  }
  return subject('event', {
    ...sanitizeDateObject(event),
    slots: event.slots.map(sanitizeDateObject),
  });
};

export const setAllDay = <K extends DateObject>(dateObject: K, startCal?: Date, endCal?: Date) => {
  const { start, end } = dateObject;
  let allDay = false;

  if (
    start.getHours() === 0 &&
    start.getMinutes() === 0 &&
    end.getHours() === 0 &&
    end.getMinutes() === 0 &&
    end.getDay() > start.getDay()
  )
    allDay = true;

  if (startCal !== undefined && endCal !== undefined && start <= startCal && endCal <= end)
    allDay = true;

  return { ...dateObject, start, end, allDay };
};

export function splitSlotsByConcurrency<T extends EventSlotEntry>(
  slots: T[],
  nth: number,
): (T & { oldIndex?: number })[][] {
  const result: (T & { oldIndex?: number })[][] = Array(nth)
    .fill(null)
    .map(() => []);

  slots.forEach((item, index) => {
    if (item.type === 'break') {
      for (let i = 0; i < nth; i++) {
        result[i].push(item);
      }
      return;
    }
    result[index % nth].push({ ...item, oldIndex: index });
  });

  return result;
}

// TODO: why are we sanitizing events with `queryStart` and `queryEnd` ?
export const getAllDaySetter = <T extends EventRef>(rawStartCal: string, rawEndCal: string) => {
  const startCal = new Date(rawStartCal);
  const endCal = new Date(rawEndCal);

  return (event: T): WithAllDay<T> => {
    const { start, end } = event;
    let allDay = false;

    if (
      start.getHours() === 0 &&
      start.getMinutes() === 0 &&
      end.getHours() === 0 &&
      end.getMinutes() === 0 &&
      end.getDay() > start.getDay()
    ) {
      allDay = true;
    }

    if (startCal !== undefined && endCal !== undefined && start <= startCal && endCal <= end) {
      allDay = true;
    }

    return { ...event, allDay };
  };
};

export function getEventPrefix(moduleRef?: ModuleRef | null) {
  return moduleRef
    ? `[${moduleRef?.code.split('_')[0]}]${
        moduleRef.activityRef ? ` [${moduleRef.activityRef?.name || ''}]` : ''
      }`
    : null;
}

export const sanitizeFormEvent = (event?: EventEntry): IEventFormInput | null =>
  event
    ? {
        ...event,
        slots:
          event.slots && event.slots.length
            ? {
                ...getSlotInput(event.slots),
                slotNumber: event.slots.reduce(
                  (total, x) => (x.type === 'slot' ? total + 1 : total),
                  0,
                ),
              }
            : DEFAULT_SLOT_PARAMS,
      }
    : null;

export function getModuleColorMapper<K extends EventSample>(
  moduleColors: ModuleColors,
  colorMode: ColorMode,
) {
  return (event: K) => {
    if (event.moduleRef && moduleColors[event.moduleRef._id]) {
      return {
        className: event.type,
        backgroundColor: moduleColors[event.moduleRef._id][colorMode],
      };
    }
    return { className: event.type, color: colorMode === 'light' ? 'lightpink' : 'purple' };
  };
}

export function isEventStudentPayload(payload: unknown): payload is EventStudentPayload {
  return (
    !!payload &&
    typeof payload === 'object' &&
    EVENT_STUDENT_PAYLOAD_FIELDS.every(field => field in payload)
  );
}

/**
 * If the event is restricted then some `fields` rules will be applied, if `permittedFields.length` is equal to 0 then there is no restrictions.
 */
export function isEventEntry(
  payload: Stored<EventEntry | EventStudentPayload>,
  ability: PanoramixAbility,
): payload is Stored<EventEntry> {
  const permitted = permittedFieldsOf(ability, 'read', payload, {
    fieldsFrom: rule => rule.fields || [],
  });
  return permitted.length === 0;
}

export function getHistoryLogCreator(history: HistoryEntry[]) {
  const createEntry = history.find(history => history.action === 'create');

  if (!createEntry) {
    return null;
  }

  return { userRef: createEntry.userRef, date: createEntry.date };
}
