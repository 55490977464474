import { GroupBase, Select as BaseSelect } from 'chakra-react-select';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { mergeChakraStyles } from '@/config/theme';

import { ISelectProps } from './Select.types';
import { getSelectDefaultComponentsOverride } from './shared';

export function SelectBase<Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
  onChange,
  components = {},
  isSearchable = false,
  isClearable = false,
  backspaceRemovesValue = false,
  isInvalid = false,
  selectRef = undefined,
  displayIcon = true,
  size = 'md',
  chakraStyles = {},
  ...otherProps
}: ISelectProps<Option, IsMulti, Group>) {
  const { t } = useTranslation('components/shared/Select');
  const mergedStyles = mergeChakraStyles<Option, IsMulti, Group>(chakraStyles);

  const overridedComponents = React.useMemo(
    () => ({
      ...getSelectDefaultComponentsOverride<Option, IsMulti, Group>(),
      ...components,
    }),
    [components],
  );

  return (
    <BaseSelect
      ref={selectRef}
      isSearchable={isSearchable}
      isClearable={isClearable}
      backspaceRemovesValue={backspaceRemovesValue}
      size={size}
      onChange={onChange}
      placeholder={t('placeholder_label')}
      isInvalid={isInvalid}
      components={{
        ...overridedComponents,
        DropdownIndicator: displayIcon ? overridedComponents.DropdownIndicator : undefined,
      }}
      noOptionsMessage={({ inputValue }) => (!inputValue ? t('no_options') : '')}
      {...otherProps}
      chakraStyles={mergedStyles}
    />
  );
}
