import { ChakraStylesConfig, GroupBase, StylesFunction } from 'chakra-react-select';

/** We use chakra-react-select that is a wrapper around the select document.
 * Look at the documentation (https://www.npmjs.com/package/chakra-react-select) for more information.
 */

const variants = {
  ghost: {
    control: {
      bg: 'transparent',
      border: 'none',
      _focus: {
        boxShadow: 'none',
      },
    },
  },
};

const getSelectChakraStylesConfig = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(): ChakraStylesConfig<Option, IsMulti, Group> => ({
  control: (provided, state) => {
    const variant = state.selectProps.variant as keyof typeof variants;

    return {
      ...provided,
      cursor: 'pointer',
      bgColor: 'containerBgColor',
      color: 'primaryFontColor',
      borderColor: 'inputBorderColor',
      borderRadius: 'md',
      _disabled: {
        bgColor: 'disabledBgColor',
      },
      _hover: {
        bgColor: 'disabledBgColor',
      },
      ...variants[variant]?.control,
    };
  },
  valueContainer: provided => ({
    ...provided,
    paddingRight: '0',
  }),
  input: provided => ({
    ...provided,
    gridArea: '1/2/1/4',
  }),
  singleValue: provided => ({
    ...provided,
    width: 'full',
    display: 'flex',
  }),
  menu: provided => ({
    ...provided,
    boxShadow: 'lg',
    /** necessary in the case of ForceRegisterGroup where we need to see the members of each group on `:hover` status */
    overflow: 'visible',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'mainPlaceholderColor',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    bgColor: state.isDisabled ? 'disabledBgColor' : 'mainSelectIconBgColor',
  }),
});

export function mergeChakraStyles<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  override: ChakraStylesConfig<Option, IsMulti, Group>,
) {
  const base = getSelectChakraStylesConfig<Option, IsMulti, Group>();
  const merged = { ...base };

  Object.keys(override).forEach(key => {
    const baseFunction = base[
      key as keyof ChakraStylesConfig<Option, IsMulti, Group>
    ] as StylesFunction<unknown>;
    const overrideFunction = override[
      key as keyof ChakraStylesConfig<Option, IsMulti, Group>
    ] as StylesFunction<unknown>;

    if (baseFunction) {
      merged[key as keyof ChakraStylesConfig<Option, IsMulti, Group>] = (provided, state) => {
        const baseResult = baseFunction(provided, state);
        return {
          ...baseResult,
          ...overrideFunction(baseResult, state),
        };
      };
    } else {
      merged[key as keyof ChakraStylesConfig<Option, IsMulti, Group>] = overrideFunction;
    }
  });
  return merged;
}
