import { chakraComponents, SingleValueProps } from 'chakra-react-select';
import React from 'react';

import { UserItem } from '../UserItem';
import type { IUserOption } from './helpers';

export function UserValue<IsMulti extends boolean = false>({
  children,
  ...props
}: React.PropsWithChildren<SingleValueProps<IUserOption, IsMulti>>) {
  const { data } = props;

  return (
    <chakraComponents.SingleValue {...props}>
      <UserItem value={data.value} />
    </chakraComponents.SingleValue>
  );
}
