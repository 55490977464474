import { ERRORS_EN, ERRORS_FR } from '@epitech/ops-panoramix-types';
import { format } from 'date-fns';
import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { getDateFnsLanguage } from '@/config/localization';

i18n
  .use(Backend)
  //  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['global'],
    lng: 'en',
    fallbackLng: 'en',
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format(value: Date, formatting: string | undefined, lng: string | undefined) {
        const localDateFns = getDateFnsLanguage(lng ?? 'enGB');

        return format(value, formatting ?? 'yyyy-MM-dd', { locale: localDateFns });
      },
    },
  });

i18n.addResourceBundle('en', 'form_errors', ERRORS_EN);
i18n.addResourceBundle('fr', 'form_errors', ERRORS_FR);

export default i18n;
