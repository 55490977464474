import {
  browserTracingIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { environment } from '../environment';

const { DSN, PROFILES_SAMPLE_RATE, TRACES_SAMPLE_RATE, NORMALIZE_DEPTH } = environment.sentry;

init({
  dsn: DSN,
  environment: environment.ENV,
  integrations: [
    browserTracingIntegration(),
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  normalizeDepth: NORMALIZE_DEPTH,
  tracesSampleRate: TRACES_SAMPLE_RATE,
  profilesSampleRate: PROFILES_SAMPLE_RATE,
});
