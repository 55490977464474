import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, Center, Heading, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaMicrosoft } from 'react-icons/fa';
import { Navigate, useLocation } from 'react-router-dom';

import { loginRequest } from '@/config/authentication';
import { PANORAMIX_ROUTES } from '@/config/constants';
import { environment } from '@/config/environment';
import { getSearchParams } from '@/lib/helpers/utils';

type LocationState = {
  from?: {
    pathname: string;
    search: string;
  };
};

export const AuthenticationPage: React.FC = () => {
  const { t } = useTranslation('pages/Authentication');
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const from = (location?.state as LocationState)?.from;

  const handleLogin = () => instance.loginPopup(loginRequest);

  const pathname = from?.pathname;
  const search = getSearchParams(pathname as (typeof PANORAMIX_ROUTES)[number]).toString();

  return isAuthenticated ? (
    <Navigate
      to={{
        pathname,
        search,
      }}
    />
  ) : (
    <Center h="full" w="full">
      <VStack spacing={8}>
        <Heading size="2xl">{environment.NAME}</Heading>
        <Button size="lg" rightIcon={<FaMicrosoft />} colorScheme="blue" onClick={handleLogin}>
          {t('connect')}
        </Button>
      </VStack>
    </Center>
  );
};
