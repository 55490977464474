import { ComponentStyleConfig } from '@chakra-ui/react';

export const Textarea: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'md',
  },
  variants: {
    white: {
      bgColor: 'containerBgColor',
      color: 'primaryFontColor',
      border: '1px solid',
      borderColor: 'gray.200',
      _placeholder: {
        color: 'mainPlaceholderColor',
      },
      _focus: {
        borderColor: '#3184ce',
        boxShadow: '0 0 0 1px #3184ce',
      },
    },
  },
};
