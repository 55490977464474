import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { useSelector } from './useSelector';

export default function useProfilePublicHoliday() {
  const profilePublicHoliday = useSelector(state => state.settings.publicHoliday, shallowEqual);

  return useMemo(() => profilePublicHoliday, [profilePublicHoliday]);
}
