import { PanoramixActions, PanoramixSubjects } from '@/config/providers';
import i18n from '@/lib/hooks/i18n';

export interface IError extends Error {
  status: number;
  data: {
    message?: string;
  };
}

const mapAction = (action: PanoramixActions) => {
  switch (action) {
    case 'create':
      return i18n.t('create');
    case 'delete':
      return i18n.t('delete');
    case 'read':
      return i18n.t('read');
    case 'update':
      return i18n.t('update');
    default:
      return i18n.t('defaultAction');
  }
};

const mapResource = (resource: PanoramixSubjects) => {
  switch (resource) {
    case 'event':
    case 'module':
    case 'activity':
    case 'cohort':
    case 'authorizationGroup':
    case 'user':
    case 'room':
      return i18n.t(resource);
    default:
      return i18n.t('defaultResource');
  }
};

const mapPermissionError = (action: PanoramixActions, resource: PanoramixSubjects) => {
  if (resource === 'event' || resource === 'eventCohortGroup') {
    return i18n.t('eventNotAllowed', {
      action: mapAction(action),
    });
  }
  return i18n.t('notAllowed', {
    action: mapAction(action),
    resource: mapResource(resource),
  });
};

export const sanitizeError = (ex: IError) => {
  const rgx = /^.*\[(.*)\/(.*)\]$/;

  const matches = (
    ex.data.message ? ex.data.message.match(rgx) : [undefined, undefined, undefined]
  ) as [string?, PanoramixActions?, PanoramixSubjects?];
  switch (ex.status) {
    case 403:
      if (matches[1] && matches[2]) {
        return mapPermissionError(matches[1], matches[2]);
      }
      return i18n.t('403');
    case 422:
      return i18n.t('422');
    case 500:
      return i18n.t('500');
    case 401:
      return i18n.t('401');
    case 404:
      return i18n.t('404');
    case 409:
      if (ex.data.message) {
        return i18n.t('409_' + ex.data.message);
      }
      return i18n.t('409');
    default:
      return undefined;
  }
};
