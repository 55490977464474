export const providesList = <R extends { _id?: string }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
) =>
  resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map(({ _id }) => ({
          type: tagType,
          id: _id,
        })),
      ]
    : [{ type: tagType, id: 'LIST' }];

export interface IPaginatedRequest {
  limit?: number;
  offset?: number;
}

export const DEFAULT_PAGINATION: IPaginatedRequest = {
  limit: 10,
  offset: 0,
};
