import type { RawRuleOf } from '@casl/ability';
import type { PackRule } from '@casl/ability/extra';
import {
  CohortGroup,
  CohortRef,
  ICohortType,
  ModuleRef,
  UserRef,
} from '@epitech/ops-panoramix-types';
import type {
  CohortEntry,
  ProfileSettingsEntry,
  ProfileSettingsInput,
  UserEntry,
} from '@epitech/ops-panoramix-users-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

import { environment } from '@/config/environment';
import { PanoramixAbility } from '@/config/providers';
import { Stored } from '@/lib/types/utils';

import { customFetchQuery } from './baseQueryWithReauth';
import { DEFAULT_PAGINATION, IPaginatedRequest, providesList } from './helpers';

export interface ILoginResponse {
  user: Stored<UserEntry>;
  jwt: string;
  permissions: PackRule<RawRuleOf<PanoramixAbility>>[];
}

export interface ILoginRequest {
  login: string;
  accessToken: string;
}

export interface ISearchUsersRequest extends IPaginatedRequest {
  moduleRef?: ModuleRef | null;
  cohortGroups?: CohortGroup[];
  search?: string;
  logas?: boolean;
}

export interface ISearchStaffsRequest extends IPaginatedRequest {
  moduleRef?: ModuleRef | null;
  cohortGroups?: CohortGroup[];
  search?: string;
}

export interface ISearchCohortsRequest extends IPaginatedRequest {
  search?: string;
  type?: ICohortType;
}

export interface IJoinCohorteRequest {
  cohortId: string;
  userRef: UserRef;
}

export interface ILeaveCohorteRequest {
  cohortId: string;
  userId: string;
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: customFetchQuery(environment.services.USERS_URI),
  tagTypes: ['Cohorts', 'CohortGroups', 'CohortsRef', 'UsersRef', 'ProfileSettings'],
  endpoints: builder => ({
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: ({ accessToken, login }) => ({
        url: '/users/login',
        method: 'POST',
        body: { accessToken, login },
        credentials: 'include',
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({ url: '/users/logout', method: 'POST', credentials: 'include' }),
    }),
    getToken: builder.query<Pick<ILoginResponse, 'jwt'>, void>({
      query: () => ({ url: '/users/token', method: 'GET', credentials: 'include' }),
    }),
    getLogasToken: builder.query<ILoginResponse, string>({
      query: (userId: string) => `/users/token/${userId}`,
    }),
    searchUsers: builder.query<Stored<UserRef>[], ISearchUsersRequest>({
      query: ({
        logas,
        search,
        moduleRef,
        cohortGroups = [],
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) => {
        return queryString.stringifyUrl(
          {
            url: '/users',
            query: {
              refOnly: true,
              search,
              logas,
              moduleId: moduleRef?._id,
              cohortGroupIds: cohortGroups.map(cg => CohortGroup.getCohortGroupHash(cg)),
              limit,
              offset,
            },
          },
          { arrayFormat: environment.QUERY_ARRAY_PARSER },
        );
      },
      providesTags: users => providesList(users, 'UsersRef'),
    }),
    searchStaffs: builder.query<Stored<UserRef>[], ISearchStaffsRequest>({
      query: ({
        search,
        moduleRef,
        cohortGroups = [],
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) => {
        return queryString.stringifyUrl(
          {
            url: '/authorizationGroups/users',
            query: {
              moduleId: moduleRef?._id,
              moduleSources: moduleRef?.tpIds.map(tpId => tpId.source),
              cohortGroupIds: cohortGroups.map(cg => CohortGroup.getCohortGroupHash(cg)),
              search,
              limit,
              offset,
              refOnly: true,
            },
          },
          { arrayFormat: environment.QUERY_ARRAY_PARSER },
        );
      },
      providesTags: users => providesList(users, 'UsersRef'),
    }),
    getCohorts: builder.query<Stored<CohortEntry>[], IPaginatedRequest>({
      query: ({ limit = DEFAULT_PAGINATION['limit'], offset = DEFAULT_PAGINATION['offset'] }) =>
        queryString.stringifyUrl({
          url: '/cohorts',
          query: {
            offset,
            limit,
          },
        }),
      providesTags: cohorts => providesList(cohorts, 'Cohorts'),
    }),
    searchCohorts: builder.query<CohortRef[], ISearchCohortsRequest>({
      query: ({
        search,
        type,
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) =>
        queryString.stringifyUrl({
          url: '/cohorts',
          query: {
            refOnly: true,
            search,
            type,
            limit,
            offset,
          },
        }),
      providesTags: cohorts => providesList(cohorts, 'CohortsRef'),
    }),
    getUserCohortGroups: builder.query<CohortGroup[], void>({
      query: () => '/users/cohortGroups',
    }),
    getCohort: builder.query<Stored<CohortEntry>, string>({
      query: cohortId => `/cohorts/${cohortId}`,
      providesTags: cohort => [{ type: 'Cohorts', id: cohort?._id }],
    }),
    deleteCohort: builder.mutation<void, string>({
      query: cohortId => ({
        url: `/cohorts/${cohortId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Cohorts', id: 'LIST' }],
    }),
    joinCohort: builder.mutation<CohortEntry, IJoinCohorteRequest>({
      query: ({ cohortId, userRef }) => ({
        url: `/cohorts/${cohortId}/users`,
        method: 'POST',
        body: userRef,
      }),
      invalidatesTags: [{ type: 'Cohorts', id: 'LIST' }],
    }),
    leaveCohort: builder.mutation<CohortEntry, ILeaveCohorteRequest>({
      query: ({ cohortId, userId }) => ({
        url: `/cohorts/${cohortId}/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Cohorts', id: 'LIST' }],
    }),
    updateSettings: builder.mutation<void, ProfileSettingsInput>({
      query: profileSettings => ({
        url: `/users/settings`,
        method: 'PUT',
        body: profileSettings,
      }),
      invalidatesTags: [{ type: 'ProfileSettings' }],
    }),
    getSettings: builder.query<ProfileSettingsEntry, void>({
      query: () => `/users/settings`,
      providesTags: [{ type: 'ProfileSettings' }],
    }),
  }),
});

export const useFetchUserCohortGroupState = usersApi.endpoints.getUserCohortGroups.useQueryState;

export const {
  useLazySearchCohortsQuery,
  useSearchUsersQuery,
  useLazySearchUsersQuery,
  useLazySearchStaffsQuery,
  useGetTokenQuery,
  useLazyGetTokenQuery,
  useGetCohortQuery,
  useLazyGetCohortQuery,
  useGetUserCohortGroupsQuery,
  useLazyGetUserCohortGroupsQuery,
  useGetCohortsQuery,
  useLazyGetCohortsQuery,
  useLoginMutation,
  useLogoutMutation,
  useLeaveCohortMutation,
  useDeleteCohortMutation,
  useJoinCohortMutation,
  useLazyGetLogasTokenQuery,
  useUpdateSettingsMutation,
  useGetSettingsQuery,
} = usersApi;
