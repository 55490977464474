import '@fontsource/open-sans';
import '@fontsource/raleway';
/** Necessary to load date-picker styles before loading our chakra theme for overrides to happen properly */
import 'react-datepicker/dist/react-datepicker.min.css';

import { ChakraProvider } from '@chakra-ui/react';
import React, { ComponentProps } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { ability, AbilityContext } from './ability.context';

interface IProps {
  store: ComponentProps<typeof Provider>['store'];
  persistor: Persistor;
  theme: ComponentProps<typeof ChakraProvider>['theme'];
  children: React.ReactNode;
}

function ProvidersWrapper({ store, persistor, theme, children }: IProps) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme} resetCSS={true}>
          <BrowserRouter>
            <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
          </BrowserRouter>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  );
}

export default ProvidersWrapper;
