import { Box, HStack, Modal, ModalContent, ModalOverlay, Spinner, VStack } from '@chakra-ui/react';

export function LoadingModal() {
  return (
    <Modal isOpen={true} onClose={() => {}} variant="wide" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <VStack p={4}>
          <HStack spacing={4}>
            <Box>Loading...</Box>
            <Spinner />
          </HStack>
        </VStack>
      </ModalContent>
    </Modal>
  );
}
