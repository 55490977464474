import {
  Icon,
  IconButton,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';

interface CityListProps {
  cities: string[];
}

function _CityList({ cities }: CityListProps) {
  const { t } = useTranslation('components/events');
  return (
    <Popover placement="top" trigger="hover" isLazy>
      <PopoverTrigger>
        <IconButton
          aria-label={t('more_cities')}
          icon={<Icon as={FiMoreHorizontal} />}
          size="xs"
          variant="ghost"
        />
      </PopoverTrigger>
      <PopoverContent width="fit-content" p={2}>
        <PopoverArrow />
        <PopoverBody as={List}>
          {cities.map((city, index) => {
            return (
              <Text padding={1} key={index} as={ListItem}>
                {city}
              </Text>
            );
          })}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export const CityList = React.memo(_CityList);
