import { DEFAULT_QUERY_FILTERS } from '@/components/calendar/filters/utils';
import { environment } from '@/config/environment';

const { DEFAULT_VIEW, DEFAULT_START, DEFAULT_END, DEFAULT_ROOM_VIEW } = environment.calendar;

export const DEFAULT_CALENDAR_PARAMS = {
  view: DEFAULT_VIEW,
  start: DEFAULT_START.toISOString(),
  end: DEFAULT_END.toISOString(),
  ...DEFAULT_QUERY_FILTERS,
};

export const DEFAULT_ROOMS_PARAMS = {
  view: DEFAULT_ROOM_VIEW,
  start: DEFAULT_START.toISOString(),
  end: DEFAULT_END.toISOString(),
};
