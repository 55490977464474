import { Icon, InputProps } from '@chakra-ui/react';
import { chakraComponents, DropdownIndicatorProps, GroupBase } from 'chakra-react-select';
import React, { PropsWithChildren } from 'react';
import { FiChevronDown, FiSearch } from 'react-icons/fi';

function _getIconSize(size: InputProps['size']) {
  switch (size) {
    case 'lg':
      return 6;
    case 'md':
      return 4;
    default:
      return 2;
  }
}

export function CustomIndicatorDropdown<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  innerProps,
  isFocused,
  selectProps,
  ...props
}: PropsWithChildren<DropdownIndicatorProps<Option, IsMulti, Group>>): React.ReactElement | null {
  const { isSearchable, size } = selectProps;

  const iconSize = _getIconSize(size);

  return (
    <chakraComponents.DropdownIndicator
      innerProps={innerProps}
      isFocused={isFocused}
      selectProps={selectProps}
      {...props}
    >
      <Icon
        as={isSearchable ? FiSearch : FiChevronDown}
        size={iconSize}
        h={iconSize}
        w={iconSize}
        transition="all 0.4s"
        color={isFocused ? 'selectIconFocusedColor' : 'selectIconColor'}
      />
    </chakraComponents.DropdownIndicator>
  );
}
