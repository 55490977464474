import { createReduxEnhancer } from '@sentry/react';

export const sentryReduxEnhancer = createReduxEnhancer({
  /** We do not want to send the whole state to Sentry since it contains rtk internal data not relevant for debugging */
  stateTransformer: state => ({ settings: state.settings, permissions: state.auth?.permissions }),
  configureScopeWithState: (scope, state) => {
    if (state.auth.user && scope.getUser()?.id !== state.auth.user._id) {
      scope.setUser({
        id: state.auth.user._id,
        email: state.auth.user.login,
        name: `${state.auth.user.firstName} ${state.auth.user.lastName}`,
        jwt: state.auth.jwt,
      });
      scope.setTag('logas', false);
      if (state.auth.logasUser) {
        scope.setTag('logas', true);
        scope.setContext('Original User', {
          id: state.auth.logasUser.user._id,
          email: state.auth.logasUser.user.login,
          name: `${state.auth.logasUser.user.firstName} ${state.auth.logasUser.user.lastName}`,
          jwt: state.auth.logasUser.jwt,
        });
      }
    }
  },
});
