import { accordionAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

export const baseStyle = definePartsStyle({
  container: {
    '&:last-of-type': {
      borderBottom: 'none',
    },
    '&:first-of-type': {
      borderTop: 'none',
    },
  },
  panel: {
    p: 0,
  },
});

export const Accordion = defineMultiStyleConfig({
  baseStyle,
});
