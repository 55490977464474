import { useColorMode } from '@chakra-ui/react';
import { useEffect } from 'react';

import i18n from './i18n';
import useProfileSettings from './useProfileSettings';

export async function useProfileSettingsToInitFront() {
  const { setColorMode } = useColorMode();
  const profileSettings = useProfileSettings();

  useEffect(() => {
    setColorMode(profileSettings.displayTheme);
    i18n.changeLanguage(profileSettings.language);
  }, [profileSettings.displayTheme, profileSettings.language, setColorMode]);
}
