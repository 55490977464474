import { ComponentStyleConfig } from '@chakra-ui/react';

export const Container: ComponentStyleConfig = {
  baseStyle: {
    '::-webkit-scrollbar': {
      width: '10px',
      border: 'none',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#e0e0e0',
      border: 'none',
    },
  },
};
