import { GroupBase, SelectComponentsConfig } from 'chakra-react-select';

import { CustomIndicatorDropdown } from './overrides';

export const getSelectDefaultComponentsOverride = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(): SelectComponentsConfig<Option, IsMulti, Group> => ({
  DropdownIndicator: CustomIndicatorDropdown,
});
