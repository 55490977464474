import { ComponentStyleConfig, defineStyle } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  variants: {
    solid: defineStyle(({ colorScheme }) => {
      switch (colorScheme) {
        case 'shadow':
          return {
            bgColor: 'buttonBgColorDark',
            color: 'white',
            ':hover': {
              bgColor: 'buttonBgColorDarkHover',
            },
            ':active': {
              bgColor: 'buttonBgColorDarkActive',
            },
          };
        case 'white':
          return {
            bgColor: 'containerBgColor',
            color: 'primaryFontColor',
            ':hover': {
              bgColor: 'itemBgColorHover',
            },
            ':active': {
              bgColor: 'itemBgColorHover',
            },
          };

        default:
          return {};
      }
    }),
  },
};
