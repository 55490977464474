import { Icon, IconButton } from '@chakra-ui/react';
import { chakraComponents, GroupBase, IndicatorsContainerProps } from 'chakra-react-select';
import React from 'react';
import { FiCheck } from 'react-icons/fi';

export function SubmitSingleButton<T>({
  ...props
}: IndicatorsContainerProps<T, false, GroupBase<T>>) {
  const { selectProps } = props;

  return (
    <chakraComponents.IndicatorsContainer<T, false, GroupBase<T>> {...props}>
      <IconButton
        ml="auto"
        borderRadius={0}
        size="md"
        icon={<Icon as={FiCheck} />}
        aria-label="save"
        type="submit"
        isDisabled={!props.hasValue}
        colorScheme="green"
        isLoading={selectProps.isLoading}
      />
    </chakraComponents.IndicatorsContainer>
  );
}
