import { UserEntry } from '@epitech/ops-panoramix-users-types';
import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { selectCurrentUser, selectLogasUser } from '@/store/services/authentication.slice';

import { Stored } from '../types/utils';
import { useSelector } from './useSelector';

export const useAuth = () => {
  const permissions = useSelector(state => state.auth.permissions, shallowEqual);
  const user = useSelector(
    state => selectCurrentUser(state.auth),
    shallowEqual,
  ) as Stored<UserEntry>;
  const logasUser = useSelector(
    state => selectLogasUser(state.auth),
    shallowEqual,
  ) as Stored<UserEntry> | null;

  return useMemo(() => ({ user, logasUser, permissions }), [user, logasUser, permissions]);
};
