import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import React from 'react';

type MenuItemButtonProps = {
  icon: React.ElementType;
  children: React.ReactNode;
} & ButtonProps;

/** We have to override `Chakra@MenuItem` since it causes weird behavior such as closing all popovers when hovered */
function _MenuItemButton({ icon, children, ...props }: MenuItemButtonProps) {
  return (
    <Button
      px={2.5}
      variant="ghost"
      leftIcon={<Icon as={icon} />}
      fontWeight="normal"
      w="full"
      justifyContent="start"
      borderRadius={0}
      {...props}
    >
      {children}
    </Button>
  );
}

export const MenuItemButton = React.memo(_MenuItemButton);
