import { Spinner } from '@chakra-ui/react';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import InitAuthWrapper from './InitAuthWrapper';
import { useTokenRestore } from './useTokenRestore';

function _AuthGuard(): React.ReactElement {
  const { isAuthenticatedOffice, authedUser } = useTokenRestore();
  const location = useLocation();

  if (isAuthenticatedOffice && !authedUser) {
    return (
      <Spinner
        color="grey.500"
        size="lg"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    );
  }
  if (isAuthenticatedOffice && authedUser) {
    return (
      <InitAuthWrapper>
        <Outlet key={authedUser._id} />
      </InitAuthWrapper>
    );
  }
  return (
    <Navigate
      to="/login"
      state={{ from: { pathname: location.pathname, search: location.search } }}
    />
  );
}

export const AuthGuard = React.memo(_AuthGuard);
