import { HStack, Tag, Text } from '@chakra-ui/react';
import { chakraComponents, GroupBase, SelectComponentsConfig } from 'chakra-react-select';
import { decode } from 'he';
import React from 'react';

import type { IModuleOption } from './helpers';

export const ModuleOption: SelectComponentsConfig<
  IModuleOption,
  false,
  GroupBase<IModuleOption>
>['Option'] = React.memo(({ children, ...props }) => (
  <chakraComponents.Option {...props}>
    <HStack justifyContent="space-between" alignItems="center" width="full">
      <Text flex="1">{children}</Text>
      <Tag ml="auto" variant="moduleCode" width="fit-content">
        {decode(props.data.value.code)}
      </Tag>
    </HStack>
  </chakraComponents.Option>
));

export const ModuleOptionMultiValue: SelectComponentsConfig<
  IModuleOption,
  true,
  GroupBase<IModuleOption>
>['Option'] = React.memo(({ children, ...props }) => (
  <chakraComponents.Option {...props}>
    {children}
    <Tag ml="auto" mr={3} overflow="hidden" maxW="30%" variant="moduleCode">
      {decode(props.data.value.code)}
    </Tag>
  </chakraComponents.Option>
));
