import { useDispatch } from 'react-redux';

import { eventsApi } from '@/store/services/events';
import { modulesApi } from '@/store/services/modules';
import { usersApi } from '@/store/services/users';

export function onLogasChange(dispatch: ReturnType<typeof useDispatch>) {
  dispatch(modulesApi.util.resetApiState());
  dispatch(eventsApi.util.resetApiState());
  dispatch(usersApi.util.resetApiState());
}
