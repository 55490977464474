import { Flex } from '@chakra-ui/react';
import { SingleValue } from 'chakra-react-select';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterLabel } from '@/components/calendar/filters/Label';
import { IModuleOption, useModuleSearch } from '@/components/modules';
import { ActivityList } from '@/components/modules/list/ActivityList';
import { AsyncSelect } from '@/components/ui-elements/Select/Async';
import { useLazyFetchModuleQuery } from '@/store/services/modules';

export function Modules() {
  const searchModules = useModuleSearch();
  const [fetchModule, { data: module }] = useLazyFetchModuleQuery();
  const [isModuleSelected, setIsModuleSelected] = useState(false);

  const onModuleChange = useCallback(
    (_module: SingleValue<IModuleOption>) => {
      if (_module === null) {
        setIsModuleSelected(false);
      }
      if (_module?.value?._id) {
        fetchModule(_module?.value?._id).unwrap();
        setIsModuleSelected(true);
      }
    },
    [fetchModule],
  );

  const { t } = useTranslation('layouts/calendar/Filters');
  const { t: tEvent } = useTranslation('components/events');

  return (
    <Flex flexDirection={'column'} background="secondBgColor" borderRadius={4} p={4}>
      <FilterLabel label="researchModuleView" />
      <AsyncSelect
        name="search module"
        size="sm"
        onChange={onModuleChange}
        loadOptions={searchModules}
        htmlDecode={true}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        noOptionsMessage={() => t('search_module_no_options')}
      />
      {module && isModuleSelected ? (
        <ActivityList module={module} />
      ) : (
        <Flex margin={4} justifyContent={'center'}>
          {tEvent('no_module_selected')}
        </Flex>
      )}
    </Flex>
  );
}
