import { useCallback } from 'react';

import { type ISearchUsersRequest, useLazySearchUsersQuery } from '@/store/services/users';

import { getUserOption, IUserOption } from './helpers';

export function useUserSearch(params: ISearchUsersRequest) {
  const [searchUsers] = useLazySearchUsersQuery();

  return useCallback(
    async (
      search: string,
      callback?: (options: IUserOption[]) => void | Promise<IUserOption[]>,
      limit?: number,
      offset?: number,
    ) => {
      const result = await searchUsers(
        {
          ...params,
          search,
          limit,
          offset,
        },
        true,
      ).unwrap();
      return result.map(getUserOption);
    },
    [searchUsers, params],
  );
}
