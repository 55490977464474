import { Box, Button } from '@chakra-ui/react';
import { EventRef } from '@epitech/ops-panoramix-types';
import { DateInput } from '@fullcalendar/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface IEventFilterActionButtonsProps {
  goToDate: (date: DateInput) => void;
  isDisabled: boolean;
  eventRef?: EventRef | null;
}

export const EventActionButtons = React.memo<IEventFilterActionButtonsProps>(
  ({ goToDate, isDisabled, eventRef }) => {
    const navigate = useNavigate();
    const { t } = useTranslation('layouts/calendar/Option');

    const onClickGoTo = () => {
      if (eventRef) {
        goToDate(eventRef.start);
      }
    };

    const onClickOpen = () => {
      navigate({
        pathname: `/calendar/events/${eventRef?._id}/details`,
        search: location.search,
      });
      return false;
    };

    return (
      <Box ml="auto">
        <Button mr={2} isDisabled={isDisabled} size="sm" variant="outline" onClick={onClickGoTo}>
          {t('GoToActionButton')}
        </Button>
        <Button size="sm" isDisabled={isDisabled} variant="outline" onClick={onClickOpen}>
          {t('OpenActionButton')}
        </Button>
      </Box>
    );
  },
);
