import { environment, EnvironmentType } from '@/config/environment';

type ColorModes = 'light' | 'dark';

const images: Record<EnvironmentType, Record<ColorModes, string>> = {
  production: {
    light: 'none',
    dark: 'none',
  },
  development: {
    light: `url(/dev-white.png)`,
    dark: `url(/dev-black.png)`,
  },
  preproduction: {
    light: `url(/ppd-white.png)`,
    dark: `url(/ppd-black.png)`,
  },
};

export const bgImageOverride = (colorMode: ColorModes) => {
  return images[environment.ENV][colorMode];
};
