import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { useSelector } from './useSelector';

export default function useProfileSettings() {
  const profileSettings = useSelector(state => state.settings.profile, shallowEqual);

  return useMemo(() => profileSettings, [profileSettings]);
}
