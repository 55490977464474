import {
  Box,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuOptionGroupProps,
  Portal,
  Switch,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSettings } from 'react-icons/fi';

interface ICalendarMenuProps {
  isTimeline: boolean;
  view: string;
  onViewChange: (view: string) => void;
  onWorkingHoursChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface VIEW_CONFIG {
  day: string;
  week: string;
  month: string;
}
type VIEW_TYPES = 'list' | 'grid' | 'timeline';

const VIEWS: Record<VIEW_TYPES, VIEW_CONFIG> = {
  list: { day: 'listDay', week: 'listWeek', month: 'listMonth' },
  grid: { day: 'timeGridDay', week: 'timeGridWeek', month: 'dayGridMonth' },
  timeline: {
    day: 'resourceTimelineDay',
    week: 'resourceTimelineWeek',
    month: 'resourceTimelineMonth',
  },
};

const findDefaultsValues = (value: string): [VIEW_TYPES, string] => {
  for (const [viewType, typeValue] of Object.entries(VIEWS)) {
    const foundkey = Object.keys(typeValue).find(key => {
      return typeValue[key as keyof VIEW_CONFIG] === value;
    });
    if (foundkey !== undefined) {
      return [viewType as VIEW_TYPES, foundkey];
    }
  }
  return ['grid', 'week'];
};

export const CalendarMenu = React.memo<ICalendarMenuProps>(
  ({ view, onViewChange, isTimeline, onWorkingHoursChange }) => {
    const { t } = useTranslation('components/events');
    const [listMode, setListMode] = useState(view.includes('list'));
    const [workingHoursMode, setworkingHoursMode] = useState(true);
    const [selectedView, setSelectedView] = useState<keyof VIEW_CONFIG>('week');
    const viewType: VIEW_TYPES = isTimeline ? 'timeline' : listMode ? 'list' : 'grid';
    const [defaultSwitch, defaultOption] = findDefaultsValues(view);

    const onChangeView = (newView: keyof VIEW_CONFIG) => {
      setSelectedView(newView);
      onViewChange(VIEWS[viewType][newView]);
    };

    const onSwitchViewToggle = (value: boolean) => {
      setListMode(value);
      const newViewType = value ? 'list' : 'grid';
      onViewChange(VIEWS[newViewType][selectedView]);
    };

    const onWorkingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setworkingHoursMode(!workingHoursMode);
      onWorkingHoursChange(event);
    };

    return (
      <Menu closeOnSelect={false} variant="white" isLazy={true}>
        <MenuButton as={IconButton} icon={<Icon as={FiSettings} />}>
          {t('menu')}
        </MenuButton>
        <Portal>
          <MenuList maxW="400px">
            {!isTimeline ? (
              <HStack as={HStack} justify="space-between" p="3">
                <Box display="flex">{t('grid')}</Box>
                <Switch
                  size="md"
                  isChecked={listMode}
                  defaultChecked={defaultSwitch === 'grid'}
                  onChange={() => onSwitchViewToggle(!listMode)}
                />
                <Box display="flex">{t('list')}</Box>
              </HStack>
            ) : (
              <HStack as={HStack} justify="center" p="3">
                <Checkbox isChecked={workingHoursMode} onChange={onWorkingChange}>
                  {t('working_hours_only')}
                </Checkbox>
              </HStack>
            )}
            <MenuDivider />
            <MenuOptionGroup
              defaultValue={defaultOption}
              title={t('view')}
              type="radio"
              onChange={onChangeView as MenuOptionGroupProps['onChange']}
            >
              <MenuItemOption value="month">{t('month')}</MenuItemOption>
              <MenuItemOption value="week">{t('week')}</MenuItemOption>
              <MenuItemOption value="day">{t('day')}</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Portal>
      </Menu>
    );
  },
);
