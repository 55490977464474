import type { UserRef } from '@epitech/ops-panoramix-types';
import type { CohortRef } from '@epitech/ops-panoramix-types';
import type { OptionBase } from 'chakra-react-select';

import { IOption } from '@/components/ui-elements/Select/types';

export interface IUserOption extends OptionBase {
  label: string;
  value: UserRef;
}

export const getCohortOption = ({ name, _id }: CohortRef): IOption => ({
  value: _id,
  label: name,
});

export const getUserOption = (user: UserRef): IUserOption => ({
  value: user,
  label: user.login,
});

export const getStaffOption = (user: UserRef): IUserOption => ({
  value: user,
  label: `${user.firstName} ${user.lastName}`,
});
