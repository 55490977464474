import { createSelector, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import session from 'redux-persist/lib/storage/session';

import { ILoginResponse, usersApi } from './users';

export type AuthStateWithoutLogas = {
  user: ILoginResponse['user'] | null;
  jwt: ILoginResponse['jwt'] | null;
  permissions: ILoginResponse['permissions'];
};

export type AuthState = AuthStateWithoutLogas & {
  logasUser: AuthStateWithoutLogas | null;
};

// // Define a type for the REHYDRATE action
// interface RehydrateAction extends PayloadAction<AuthState> {}

// // Type guard to check if the action is a REHYDRATE action with the expected payload
// function isRehydrateAction(action: AnyAction): action is RehydrateAction {
//   return action.type === REHYDRATE && action.payload && 'user' in action.payload;
// }

const DEFAULT_STATE: AuthState = {
  user: null,
  jwt: null,
  permissions: [],
  logasUser: null,
};

export const authenticationSlice = createSlice({
  name: 'auth',
  initialState: DEFAULT_STATE,
  reducers: {
    tokenReceived: (state, action: { payload: Pick<ILoginResponse, 'jwt'> }) => {
      state.jwt = action.payload.jwt;
    },
    logoutUser: () => DEFAULT_STATE,
    logoutLogasUser: state => {
      if (state.logasUser) {
        state.user = state.logasUser.user;
        state.jwt = state.logasUser.jwt;
        state.permissions = state.logasUser.permissions;
        state.logasUser = null;
      }
    },
  },
  extraReducers: builder => {
    /**
     *   NOTE: Does not seem necessary anymore since switching storage from local to session.
     *   Do not delete as this can still be useful in the future if we need storage rehydration.
     */
    // builder.addCase(REHYDRATE, (state, action: AnyAction) => {
    //   if (isRehydrateAction(action)) {
    //     const incoming = action.payload;
    //     state.user = incoming.user;
    //     state.jwt = incoming.jwt;
    //     state.permissions = incoming.permissions;
    //     state.logasUser = incoming.logasUser;
    //   }
    // });
    builder.addMatcher(usersApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      const { user, jwt, permissions } = payload;
      state.user = user;
      state.jwt = jwt;
      state.permissions = permissions;
      state.logasUser = null;
    });
    builder.addMatcher(usersApi.endpoints.logout.matchFulfilled, state => {
      state.user = null;
      state.jwt = null;
      state.permissions = [];
      state.logasUser = null;
    });
    builder.addMatcher(usersApi.endpoints.getToken.matchFulfilled, (state, { payload }) => {
      const { jwt } = payload;
      state.jwt = jwt;
    });
    builder.addMatcher(usersApi.endpoints.getLogasToken.matchFulfilled, (state, { payload }) => {
      const originalAuth = Object.assign({}, state);
      state.user = payload.user;
      state.jwt = payload.jwt;
      state.permissions = payload.permissions;
      state.logasUser = {
        user: originalAuth.user,
        jwt: originalAuth.jwt,
        permissions: originalAuth.permissions,
      };
    });
  },
});

const authPersistConfig = {
  key: 'auth',
  storage: session,
};

export const persistedAuthReducer = persistReducer(authPersistConfig, authenticationSlice.reducer);

export const { logoutUser, logoutLogasUser, tokenReceived } = authenticationSlice.actions;

/** HELPERS */
function isAdminFor(subject: 'module' | 'cohort', permissions: AuthState['permissions']) {
  /**
   * Permissions are in the form of [ACTION, SUBJECT, CONDITION].
   * We want to check if the user has the permission to [ACTION, SUBJECT] without any condition
   * thus making it in an admin.
   */
  if (permissions.some(permission => permission[1] === subject && permission.length === 2)) {
    return true;
  }
  return false;
}

/** SELECTORS  */
export const selectCurrentUser = (state: AuthState) => state.user;

export const selectIsModuleAdmin = createSelector(
  (state: AuthState) => state.permissions,
  (permissions: AuthState['permissions']) => isAdminFor('module', permissions),
);

export const selectIsCohortAdmin = createSelector(
  (state: AuthState) => state.permissions,
  (permissions: AuthState['permissions']) => isAdminFor('cohort', permissions),
);

export const selectModuleColor = createSelector(
  (state: AuthState) => state.user,
  (_state: AuthState, letter: string) => letter,
  (user: AuthState['user'], letter: string) => user?.kind + letter,
);

export const selectLogasUser = (state: AuthState) => state.logasUser?.user;

export const selectCurrentPermissions = (state: AuthState) => state.permissions;
