import { ErrorBoundary } from '@sentry/react';
import React from 'react';

import ProviderFallback from '@/components/errors/ProviderFallback';

function ErrorBoundaryWrapper({ children }: React.PropsWithChildren) {
  return <ErrorBoundary fallback={ProviderFallback}>{children}</ErrorBoundary>;
}

export default React.memo(ErrorBoundaryWrapper);
