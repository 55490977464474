import { Box } from '@chakra-ui/react';
import type { SingleValue } from 'chakra-react-select';
import React, { FormEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { IBaseItemOption } from '@/components/types';

import { AsyncSelect } from '../../Select/Async';
import { SearchMenuProps } from '../types';
import { SubmitSingleButton } from './SubmitButton';

export function SearchMenu<T extends IBaseItemOption>({
  loadOptions,
  onSubmit,
  isLoading,
  ...selectProps
}: SearchMenuProps<T>) {
  const [selected, setSelected] = React.useState<T | null>(null);
  const { t } = useTranslation('components/shared');

  const _onSubmit: FormEventHandler = e => {
    e.preventDefault();
    if (!selected) return;
    onSubmit(selected?.value);
  };

  const _onChange = (selected: SingleValue<T>) => {
    if (!selected) return;
    setSelected(selected);
  };

  return (
    <Box as="form" w="full" onSubmit={_onSubmit}>
      <AsyncSelect
        displayIcon={false}
        loadOptions={loadOptions}
        htmlDecode={true}
        placeholder={t('action_search')}
        isClearable={true}
        onChange={_onChange}
        isLoading={isLoading}
        chakraStyles={{
          singleValue: provided => ({
            ...provided,
            pr: 4,
          }),
          container: provided => ({
            ...provided,
            flex: 1,
          }),
        }}
        {...selectProps}
        components={{
          IndicatorsContainer: SubmitSingleButton,
          ...selectProps.components,
        }}
      />
    </Box>
  );
}
