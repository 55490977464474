import { ComponentStyleConfig } from '@chakra-ui/react';

export const Divider: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    separator: {
      borderColor: 'separatorBorderColor',
      borderStyle: 'solid',
    },
  },
};
